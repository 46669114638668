@forward "globalMixins";
@use "globalMixins" as *;
@use "variables" as *;
@use "sass:math";

@mixin button($color: $primary, $hoverColor: $secondary) {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: $basicFont;
  outline-offset: 0;
  text-decoration: none;
  transition: all $veryFast;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  @include bgWithContrast($color);

  @include hoverAndFocus {
    transform: scale(1.03);
    text-decoration: none;
    @include bgWithContrast($hoverColor);
    border-color: darken($hoverColor, 10%);
    cursor: pointer;
  }

  &[disabled],
  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin checkbox($size: 1em, $borderColor: $basic, $selectedColor: $primary, $selectedBorder: $selectedColor) {
  position: relative;
  margin: 0;
  cursor: pointer;

  @supports (appearance: none) {
    appearance: none;
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      border-style: solid;
      border-width: 2px;
      border-color: $borderColor;

      transition: all $veryFast;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: block;

      background: transparent
        url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9 IjAgMCAzMTYuMzggMjUwLjYiPjxwYXRoIGQ9Ik03MC40IDIxOS44YzQuNTUgNC44 NyA5LjIyIDkuNjcgMTQuMTIgMTQuMTkgMy43NSAzLjQ3IDcuNzEgNi44NSAxMS45 MyA5Ljc0IDUuNSAzLjc4IDExLjkyIDcuMDggMTguNzggNi44NSA2LjY2LS4yMyAx Mi43Ny0zLjczIDE3Ljk3LTcuNjIgOC42LTYuNDIgMTYuNjctMTUuMTcgMjMuODQt MjMuMTRMMzA1LjY4IDU0LjY5YzcuMi04IDExLjc5LTE4LjI1IDEwLjQ3LTI5LjE3 LTEuMDQtOC41OS01LjcxLTE2LjU1LTEzLjAxLTIxLjI5LTUuNjUtMy42Ny0xMi4y Ni00Ljk4LTE4LjktMy44Mi04LjkgMS41Ni0xNi41NCA3LjIyLTIyLjQ4IDEzLjc4 LTQ4LjM0IDUzLjMyLTk2LjU5IDEwNi43MS0xNDQuODUgMTYwLjExbC0zLjE5IDMu NTMtNjAuNC02Ni42M2MtOC40Mi05LjI5LTE5LjctMTMuOTQtMzEuOTItOS41Ny00 LjcyIDEuNjktOC45OSA0LjU4LTEyLjQ1IDguMTktMy44NiA0LjA0LTYuNzggOS4w Ni04LjEzIDE0LjQ5LTIuMzEgOS4yNy4zIDE4LjA3IDYuNjIgMjUuMTNsNjIuOTUg NzAuMzVaIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4K")
        no-repeat;
      background-size: 70%;
      background-position: 50% 50%;
      transition: all $veryFast;
      opacity: 0;
      @if (contrast($selectedColor) == white) {
        filter: invert(1);
      }
    }

    &:checked {
      &:before {
        border-color: $selectedBorder;
        background-color: $selectedColor;
      }

      &:after {
        opacity: 1;
      }
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;

      &:before {
        background-color: rgba($basic, 0.8);
      }
    }
  }
}

@mixin radio($size: 1em, $selectedColor: $primary, $borderColor: $basic, $selectedBorder: $selectedColor) {
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;

  @supports (appearance: none) {
    appearance: none;
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 2px;
      border-color: $borderColor;

      border-radius: 50%;
      transition: all $veryFast;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;

      width: 0;
      height: 0;

      opacity: 0;
      border-radius: 50%;
      transition: all $veryFast;
    }

    &:checked {
      &:before {
        border-color: $selectedBorder;
        background-color: $selectedColor;
      }

      &:after {
        opacity: 1;
        background-color: contrast($selectedColor);
        width: 100% * 0.45;
        height: 100% * 0.45;
      }
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;

      &:before {
        background-color: lighten($basic, 75%);
      }
    }
  }
}

@mixin switch($size: 1.25em, $color: $basic, $selectedColor: $primary) {
  position: relative;
  margin: 0;
  cursor: pointer;

  @supports (appearance: none) {
    $knobSize: calc(#{$size} - 8px);
    appearance: none;
    width: 1.25em * 2;
    height: $size;
    min-width: 1.25em * 2;
    min-height: $size;
    border-radius: 50px;
    border: 2px solid $color;
    background-color: $light;
    transition: all $fast;

    /* The toggle knob */
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      border-radius: 50%;
      width: $knobSize;
      height: $knobSize;
      background: $color;
      transition: all $fast;
    }

    /* Change color and position when checked */
    &:checked {
      background: $selectedColor;
      border: 2px solid $selectedColor;
      &:after {
        left: calc(100% - #{$knobSize} - 2px);
        background: contrast($selectedColor);
      }
    }

    /* Drop the opacity of the toggle knob when the input is disabled */
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

@mixin buttonADA($color: $primary, $hoverColor: $ADAhover) {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.2em;
  border: 2px solid $lightGrey;

  line-height: 1.1em;
  text-align: center;
  border-radius: $radius;
  font-family: $basicFont;
  outline-offset: 0;
  text-decoration: none;
  transition: all $veryFast;
  @include bgWithContrast($color);

  &[disabled],
  &[aria-disabled="true"] {
    opacity: 0.5;
  }

  @include hoverAndFocus {
    text-decoration: none;
    &:not([disabled]):not([aria-disabled="true"]) {
      @include bgWithContrast($hoverColor);
      border-color: $lightGrey;
      cursor: pointer;
    }
  }
}
