@use "variables" as *;
@use "mixins" as *;

// Box sizing rules
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Focus styles
* {
  &:focus-visible,
  &.focus-visible {
    outline-offset: 2px !important;
    outline: 4px solid $red;
  }
}

// Remove all animations and transitions for people that prefer not to see them
// However, we're no longer reading the browser preference and relying solely on the user's stored preference.
// Keeping this code commented out for posterity because the javascript logic was tricky and in case we need it again.
// @media (prefers-reduced-motion: reduce) {
//   body:not([data-reduce-animation="false"]) {
//     *,
//     *::before,
//     *::after {
//       animation-duration: 0.01ms !important;
//       animation-iteration-count: 1 !important;
//       transition-duration: 0.01ms !important;
//       scroll-behavior: auto !important;
//     }
//   }
// }

body[data-reduce-animation="true"] {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  -webkit-text-size-adjust: 100%;
}

@include activeZone("slides") {
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

// Hide html scrollbar when there's an active dialog.
html[data-dialog-hide-overflow="true"]:not([data-active-dialog="toolsIcons"]) {
  overflow: hidden !important;
}

html[data-active-dialog="utilityMenu"] {
  scrollbar-gutter: initial;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.1;
  font-size: 16px;
  font-family: $basicFont;
  position: absolute;
  width: 100%;
  height: 100%;
  &[data-is-loading="true"] {
    //background-color: $primary;
  }
}

// Make images easier to work with
img {
  max-width: 100%;
  display: block;
  height: auto;
}

// Natural flow and rhythm in articles by default
article > * + * {
  margin-top: 1em;
}

// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0.35em 0 0.75em;
  line-height: 1.55em;
}

ol,
ul {
  text-align: left;
  margin: 0.4em 0 0.75em;
}

li {
  line-height: 1.5em;
}

iframe {
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid $lightGrey;
}

button {
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
}

a {
  color: $primary;
  text-decoration: none;
  transition: $veryFast;
  font-weight: bold;

  @include hoverAndFocus {
    color: $coolBlue;
    text-decoration: underline;
  }

  .targetBlankNotifier {
    @include screenReader;
  }
  .icon-mail {
    font-size: 0.9em;
    transform: translateY(1px);
    display: inline-block;
    margin-right: 0.25em;
  }

  .icon-external-link {
    margin-left: 0.25em;
  }
}

.slideSkip,
.homeSkip,
.membershipSkip {
  @include screenReader;
  display: none;
  @include focus {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999999999999999;
    width: auto;
    height: auto;
    margin: 0;
    padding: 10px;
    clip: initial;
    text-decoration: none;
    text-align: center;
    @include bgWithContrast($primary);
  }
}

.homeSkip {
  @include activeZone("home") {
    display: block;
  }
}

.slideSkip {
  @include activeZone("slides") {
    display: block;
  }
}

.membershipSkip {
  display: block;
}

@keyframes loadingScreenMascot {
  0% {
    top: 50%;
    transform: translate(-50%, 0) scale(1);
  }
  25% {
    top: 18%;
    transform: translate(-50%, 0) scale(1) rotate(0);
  }
  40% {
    top: 20%;
  }
  100% {
    top: 20%;
    transform: translate(-50%, 0) scale(1.2) rotate(15deg);
  }
}
.loadingScreen {
  position: fixed;
  top: 50%;
  left: 50%;
  @include fillSpace;
  transform: translate(-50%, -50%);
  //z-index: 99;
  .paw {
    width: 75%;
    height: 75%;
    background: url(/images/paw.svg) no-repeat;
    position: fixed;
    top: -100px;
    left: 50px;
    z-index: -1;
    transform: rotate(-15deg) scale(0.5);
    transition: $medium all;
    opacity: 0;
    &.start {
      opacity: 1;
      transform: rotate(-15deg) scale(1);
    }
  }
  .topColor {
    content: "";
    background: $secondary;
    position: fixed;
    height: 160%;
    width: 180%;
    z-index: -1;
    transform: rotate(-15deg);
    top: 75%;
    transition: $slow all;
    opacity: 0;
    &.start {
      opacity: 1;
      top: 115px;
      .line {
        width: 140%;
        opacity: 1;
      }
    }
  }
  .bottomColor {
    content: "";
    background: $primary;
    position: fixed;
    bottom: -500px;
    height: 80%;
    width: 120%;
    z-index: 1;
    left: 0;
    opacity: 0;
    transform: rotate(0deg);
    transition: $medium all;
    &.start {
      bottom: -250px;
      opacity: 1;
      transform: rotate(-15deg);
      .text {
        clip-path: inset(-30% 0% 5% 0%);
      }
    }
  }
  .loadingScreenMascot {
    position: fixed;
    width: 750px;
    height: auto;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0) rotate(5deg);
    transition: $medium all;
    opacity: 0;
    &.start {
      animation: loadingScreenMascot 3.5s linear forwards;
      opacity: 1;
    }
  }
  .line {
    position: fixed;
    top: -3px;
    margin-top: -10px;
    left: 0;
    width: 0;
    height: 7px;
    background: url(/images/dot.svg) repeat-x;
    transition: $verySlow all;
    transition-delay: 1.5s;
    z-index: 1;
    opacity: 0;
    [data-reduce-animation="true"] & {
      transition-delay: 0s !important;
      transition: none !important;
    }
  }
  .logoWrap {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 3;
    opacity: 0;
    transition: $medium all;
    .logo {
      height: 150px;
    }
    &.start {
      opacity: 1;
    }
  }
  .text {
    font-family: $secondaryFont;
    font-size: 4em;
    position: fixed;
    top: 20px;
    transform: rotate(0deg);
    z-index: 2;
    color: white;
    font-weight: 700;
    width: 100%;
    text-align: center;
    padding-right: 11vw;
    clip-path: inset(-30% 80% 5% 20%);
    transition: 2.5s all;
    transition-delay: 1.5s;
    line-height: 1.5;
    [data-reduce-animation="true"] & {
      transition-delay: 0s !important;
      transition: none !important;
    }
    .inner {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -25px;
        width: 75px;
        height: 60px;
        background: url(/images/quote.png) no-repeat;
        opacity: 0.125;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 10px;
        right: -25px;
        width: 75px;
        height: 60px;
        background: url(/images/quote.png) no-repeat;
        opacity: 0.125;
        transform: rotate(180deg);
      }
    }
    .title {
      color: $secondary;
    }
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $xl) {
  .loadingScreen {
    .topColor.start {
      top: 165px;
    }
    .text {
      font-size: 3.5em;
    }
  }
}

@media (max-width: $lg) {
  .loadingScreen {
    .text {
      font-size: 2.75em;
    }
    .paw {
      opacity: 0.75;
      width: 65%;
      height: 65%;
      top: 60px;
    }
    .topColor.start {
      top: 220px;
    }
    .bottomColor {
      height: 75%;
    }
    .loadingScreenMascot {
      width: 650px;
      margin-top: 40px;
    }
    .logoWrap .logo {
      height: 125px;
    }
  }
}

@media (max-width: $sm) {
  html[data-dialog-hide-overflow="true"]:not([data-active-dialog="toolsIcons"]) {
    overflow: hidden !important;
  }
  iframe {
    border: none;
  }
  .loadingScreen {
    .loadingScreenMascot {
      margin-top: 60px;
    }
    .topColor.start {
      top: 360px;
    }
    .text {
      font-size: 2.125em;
    }
    .bottomColor {
      left: -50px;
      padding-left: 60px;
      width: 128%;
    }
  }
  body {
    overflow: visible;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    [data-active-dialog="utilityMenu"] & {
      overflow-x: visible;
    }
  }
}

@media (max-height: $xs) and (max-width: $sm) and (orientation: landscape) {
  body {
    overflow-x: visible;
  }
}

@media (max-width: $xs) {
  .loadingScreen {
    .loadingScreenMascot {
      margin-top: 80px;
    }
    .text {
      font-size: 2em;
      max-width: 460px;
      margin: auto;
      left: 0;
      right: 0;
      padding-right: 0;
      line-height: 1.1;
      padding-top: 20px;
      text-align: center;
      transform: translateX(20px);
    }
  }
}

@media (max-width: $xxs) {
  .loadingScreen {
    .paw {
      width: 85%;
      height: 85%;
      top: 120px;
    }
    .logoWrap {
      bottom: 20px;
      right: 30px;
      .logo {
        height: 100px;
      }
    }
    .loadingScreenMascot {
      margin-top: 110px;
    }
    .bottomColor {
      width: 136%;
    }
    .text {
      font-size: 1.6em;
      max-width: 360px;
    }
  }
}

@media (max-width: $tiny) {
  .loadingScreen {
    .loadingScreenMascot {
      margin-top: 130px;
    }
    .bottomColor {
      width: 140%;
    }
    .text {
      font-size: 1.4em;
      max-width: 330px;
      transform: translateX(40px);
    }
  }
}
