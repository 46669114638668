@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password {
  position: relative;
  max-width: 500px;

  label:not(.checkboxLabel) {
    line-height: 1;
    white-space: normal;
  }

  input:not([type="checkbox"]):not([type="radio"]) {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
    min-width: 100%;
    max-width: 100%;
    max-height: 120px;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .description {
    margin-top: 6px;
    font-size: 0.84em;
    color: rgba($basic, 0.85);

    > *:first-child {
      margin-top: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.fieldWrapper,
.umbraco-forms-field.longanswer {
  textarea {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
    min-width: 100%;
    max-width: 100%;
    max-height: 120px;
  }
}

.dropdownWrapper,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;
  margin: 0 auto;

  select {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
    min-width: 100%;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 25px 50px;

    &::-ms-expand {
      display: none;
    }
  }
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 2px solid $lightGrey;
  margin-bottom: 15px;
  padding-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  .control {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 1.2em;
    white-space: nowrap;
    cursor: pointer;
  }

  .description {
    margin-top: 6px;
    font-size: 0.84em;
    color: rgba($basic, 0.85);

    > *:first-child {
      margin-top: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.enrolleeName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  padding: 30px 20px;
  border: none;
  border-top: 5px solid $primary;
  box-shadow: 0 2px 4px $lightGrey;
  background: $light;

  legend,
  .groupLegend {
    display: inline-block;
    padding: 4px 8px;
    @include bgWithContrast($black);
  }

  .groupLegend {
    display: inline-block;
    margin-bottom: 9px;
  }

  .fieldsetGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .fieldWrapper,
    .controlWrapper {
      margin: 0;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password {
  margin: 10px auto;

  label:not(.checkboxLabel) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 3px 8px;
    font-size: 0.8em;
    @include bgWithContrast($grey);
    font-family: $genericFont;
    font-weight: 600;
  }

  input:not([type="checkbox"]):not([type="radio"]),
  textarea {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 120px;
    min-height: 48px;
    margin: 0;
    border: 1px solid $grey;
    padding: 23px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
    &:focus-visible {
      label {
        @include bgWithContrast($primary);
      }
    }
  }

  button {
    position: absolute !important;
    color: $light;
    top: 0;
    right: 0;
    height: 53px;
    width: 40px;
    @include flexCenter;
    transform: scale(0.8);
    border-radius: $radius;
    background: $primary;
    border: none;
    padding: 0;
    cursor: pointer;
    &[aria-checked="true"] {
      .icon:after {
        content: "";
        width: 3px;
        height: 20px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        transform: skewX(-45deg);
        background: $light;
      }
    }
    .icon {
      font-size: 1.2em;
    }
  }
  #Password:focus + #requirements {
    max-height: 1000px;
  }
  #requirements {
    list-style: none;
    padding: 10px 0 0 0;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 1s ease-in-out;
    li {
      display: flex;
      align-items: center;
      &.valid {
        .icon {
          @include bgWithContrast($correct);
          font-size: 0.9em;
          &:before {
            content: "a";
          }
        }
      }
      .icon {
        @include bgWithContrast($wrong);
        border-radius: 50%;
        margin-right: 5px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        font-size: 0.55em;
      }
    }
  }
}

.dropdownWrapper,
.umbraco-forms-field.dropdown {
  label:not(.checkboxLabel) {
    position: absolute;
    top: 8px;
    left: 9px;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 0;
    font-size: 0.8em;
    font-family: $primaryFont;
    color: $basic;
  }
  select {
    position: relative;
    display: block;
    width: 100%;
    min-height: 52px;
    margin: 0;
    border: 2px solid $grey;
    padding: 26px 45px 8px 8px;
    border-radius: $radius;
    background-color: $light;
    text-align: left;
  }
}

.fieldWrapper,
.umbraco-forms-field.longanswer {
  textarea {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 120px;
    min-height: 48px;
    margin: 0;
    border: 2px solid $grey;
    padding: 6px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
  }
}

form,
.formWrap {
  .error {
    display: block;
    margin: 4px 0;
    padding: 2px 4px;
    text-align: center;
    @include bgWithContrast($wrong);
    &:empty {
      display: none;
    }
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $xs) {
  .styledFieldset,
  .umbraco-forms-fieldset {
    padding: 10px;
    .fieldsetGrid {
      grid-template-columns: none;
    }
  }
}

@media (max-width: $xxs) {
  .controlWrapper {
    label {
      font-size: 1em;
      font-weight: 700;
      white-space: normal;
    }
  }
}

@media (max-width: $tiny) {
  .controlWrapper {
    label {
      font-size: 0.9em;
    }
  }
}
