@use "mixins" as *;
@use "variables" as *;

#membership .formWrap.maintenance {
  background: $secondary;
  border-color: $light;

  p {
    color: $black;
  }

  .formArea {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background: transparent;

    .rte {
      color: $light;
      padding-right: 25px;
    }

    .icon {
      flex-shrink: 0;
      flex-grow: 0;
      width: 350px;
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16em;
      border-left: 2px solid lighten($secondary, 30%);
      color: $primary;
    }
  }
}

@media (max-width: $xl) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 250px;
    height: 250px;
    font-size: 8em;
  }
}

@media (max-width: $lg) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 200px;
    height: 200px;
    font-size: 6em;
  }
}

@media (max-width: $md) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 175px;
    height: 175px;
    font-size: 4em;
  }
}

@media (max-width: $sm) {
  #membership .formWrap.maintenance {
    .formArea {
      .icon {
        width: 30%;
        height: 150px;
        font-size: 6em;
      }
    }
  }
}

@media (max-width: $xs) {
  #membership .formWrap.maintenance {
    .formArea {
      flex-direction: column-reverse;
      justify-content: center;
      .rte {
        padding-right: 0;
      }

      .icon {
        width: 75px;
        height: 75px;
        font-size: 3em;
        padding: 0;
        margin-bottom: 0;
        border-left: 0;
        border-bottom: 2px solid lighten($secondary, 30%);
      }
    }
  }
}
