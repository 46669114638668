@font-face {
  font-family: "georgiaState";
  src:
    url("../fonts/georgiaState.woff2?wjr9q9") format("woff2"),
    url("../fonts/georgiaState.ttf?wjr9q9") format("truetype"),
    url("../fonts/georgiaState.woff?wjr9q9") format("woff"),
    url("../fonts/georgiaState.svg?wjr9q9#georgiaState") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "georgiaState" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-student:before {
  content: "\57";
}
.icon-mandatory:before {
  content: "\e902";
}
.icon-slideRevealVideo:before {
  content: "\53";
}
.icon-slideMasterTest:before {
  content: "\4f";
}
.icon-slideQuiz:before {
  content: "\48";
}
.icon-expand:before {
  content: "\45";
}
.icon-minify:before {
  content: "\44";
}
.icon-horzNav:before {
  content: "\42";
}
.icon-slideInterestBoard:before {
  content: "\58";
}
.icon-slideSelfDirected:before {
  content: "\4c";
}
.icon-left-arrow:before {
  content: "\6c";
}
.icon-right-arrow:before {
  content: "\6d";
}
.icon-quickview:before {
  content: "\6a";
}
.icon-slideThisOrThatQuiz:before {
  content: "\56";
}
.icon-slideForm:before {
  content: "\54";
}
.icon-slideChecklist:before {
  content: "\52";
}
.icon-slideSurvey:before {
  content: "\50";
}
.icon-slideWordSearch:before {
  content: "\4e";
}
.icon-logout:before {
  content: "\47";
}
.icon-accessibility:before {
  content: "\43";
}
.icon-pause:before {
  content: "\78";
}
.icon-tools:before {
  content: "\79";
}
.icon-menu:before {
  content: "\6e";
}
.icon-maintenance:before {
  content: "\7a";
}
.icon-achievement:before {
  content: "\74";
}
.icon-slideview:before {
  content: "\69";
}
.icon-slideVideoGrid:before {
  content: "\55";
}
.icon-slideMatchingGame:before {
  content: "\4d";
}
.icon-announce:before {
  content: "\65";
}
.icon-slideCopy:before {
  content: "\4a";
}
.icon-errorPage:before {
  content: "\41";
}
.icon-glossary:before {
  content: "\71";
}
.icon-print:before {
  content: "\6f";
}
.icon-home:before {
  content: "\67";
}
.icon-link:before {
  content: "\64";
}
.icon-slideReveal:before {
  content: "\4b";
}
.icon-slideVideo:before {
  content: "\49";
}
.icon-external-link:before {
  content: "\46";
}
.icon-play:before {
  content: "\77";
}
.icon-faq:before {
  content: "\72";
}
.icon-logout1:before {
  content: "\73";
}
.icon-pdf:before {
  content: "\70";
}
.icon-account:before {
  content: "\6b";
}
.icon-search:before {
  content: "\66";
}
.icon-lock:before {
  content: "\63";
}
.icon-minus:before {
  content: "\76";
}
.icon-plus:before {
  content: "\75";
}
.icon-slideItemsOfInterest:before {
  content: "\51";
}
.icon-x:before {
  content: "\62";
}
.icon-check:before {
  content: "\61";
}
.icon-learn-more:before {
  content: "\68";
}
.icon-arrow-next:before {
  content: "\e900";
}
.icon-arrow-previous:before {
  content: "\e901";
}
