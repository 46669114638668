@font-face {
  font-family: "georgiaState";
  src: url("../fonts/georgiaState.woff2?wjr9q9") format("woff2"), url("../fonts/georgiaState.ttf?wjr9q9") format("truetype"), url("../fonts/georgiaState.woff?wjr9q9") format("woff"), url("../fonts/georgiaState.svg?wjr9q9#georgiaState") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "georgiaState" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-student:before {
  content: "W";
}

.icon-mandatory:before {
  content: "\e902";
}

.icon-slideRevealVideo:before {
  content: "S";
}

.icon-slideMasterTest:before {
  content: "O";
}

.icon-slideQuiz:before {
  content: "H";
}

.icon-expand:before {
  content: "E";
}

.icon-minify:before {
  content: "D";
}

.icon-horzNav:before {
  content: "B";
}

.icon-slideInterestBoard:before {
  content: "X";
}

.icon-slideSelfDirected:before {
  content: "L";
}

.icon-left-arrow:before {
  content: "l";
}

.icon-right-arrow:before {
  content: "m";
}

.icon-quickview:before {
  content: "j";
}

.icon-slideThisOrThatQuiz:before {
  content: "V";
}

.icon-slideForm:before {
  content: "T";
}

.icon-slideChecklist:before {
  content: "R";
}

.icon-slideSurvey:before {
  content: "P";
}

.icon-slideWordSearch:before {
  content: "N";
}

.icon-logout:before {
  content: "G";
}

.icon-accessibility:before {
  content: "C";
}

.icon-pause:before {
  content: "x";
}

.icon-tools:before {
  content: "y";
}

.icon-menu:before {
  content: "n";
}

.icon-maintenance:before {
  content: "z";
}

.icon-achievement:before {
  content: "t";
}

.icon-slideview:before {
  content: "i";
}

.icon-slideVideoGrid:before {
  content: "U";
}

.icon-slideMatchingGame:before {
  content: "M";
}

.icon-announce:before {
  content: "e";
}

.icon-slideCopy:before {
  content: "J";
}

.icon-errorPage:before {
  content: "A";
}

.icon-glossary:before {
  content: "q";
}

.icon-print:before {
  content: "o";
}

.icon-home:before {
  content: "g";
}

.icon-link:before {
  content: "d";
}

.icon-slideReveal:before {
  content: "K";
}

.icon-slideVideo:before {
  content: "I";
}

.icon-external-link:before {
  content: "F";
}

.icon-play:before {
  content: "w";
}

.icon-faq:before {
  content: "r";
}

.icon-logout1:before {
  content: "s";
}

.icon-pdf:before {
  content: "p";
}

.icon-account:before {
  content: "k";
}

.icon-search:before {
  content: "f";
}

.icon-lock:before {
  content: "c";
}

.icon-minus:before {
  content: "v";
}

.icon-plus:before {
  content: "u";
}

.icon-slideItemsOfInterest:before {
  content: "Q";
}

.icon-x:before {
  content: "b";
}

.icon-check:before {
  content: "a";
}

.icon-learn-more:before {
  content: "h";
}

.icon-arrow-next:before {
  content: "\e900";
}

.icon-arrow-previous:before {
  content: "\e901";
}

/*---TEXT AND FONT---*/
/*---BACKGROUND---*/
/*---POSITIONING---*/
/*---CONTENT---*/
/*---LISTS---*/
/*---BORDER ARROWS---*/
/**Forms**/
/** DEFAULT OVERRIDES **/
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible, *.focus-visible {
  outline-offset: 2px !important;
  outline: 4px solid #cc0000;
}

body[data-reduce-animation=true] *,
body[data-reduce-animation=true] *::before,
body[data-reduce-animation=true] *::after {
  animation-duration: 0.01ms !important;
  animation-iteration-count: 1 !important;
  transition-duration: 0.01ms !important;
  scroll-behavior: auto !important;
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  -webkit-text-size-adjust: 100%;
}

[data-active-zone=slides] {
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

html[data-dialog-hide-overflow=true]:not([data-active-dialog=toolsIcons]) {
  overflow: hidden !important;
}

html[data-active-dialog=utilityMenu] {
  scrollbar-gutter: initial;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.1;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
}
img {
  max-width: 100%;
  display: block;
  height: auto;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0.35em 0 0.75em;
  line-height: 1.55em;
}

ol,
ul {
  text-align: left;
  margin: 0.4em 0 0.75em;
}

li {
  line-height: 1.5em;
}

iframe {
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid #eeeeee;
}

button {
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
}

a {
  color: #0039a6;
  text-decoration: none;
  transition: 200ms;
  font-weight: bold;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    color: #0071ce;
    text-decoration: underline;
  }
}
[data-browser=ie] a:hover {
  color: #0071ce;
  text-decoration: underline;
}
.ie a:hover {
  color: #0071ce;
  text-decoration: underline;
}
a.focus-visible {
  color: #0071ce;
  text-decoration: underline;
}
a:focus-visible {
  color: #0071ce;
  text-decoration: underline;
}
a .targetBlankNotifier {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
a .icon-mail {
  font-size: 0.9em;
  transform: translateY(1px);
  display: inline-block;
  margin-right: 0.25em;
}
a .icon-external-link {
  margin-left: 0.25em;
}

.slideSkip,
.homeSkip,
.membershipSkip {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  display: none;
}
.slideSkip.focus-visible,
.homeSkip.focus-visible,
.membershipSkip.focus-visible {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999999999999999;
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px;
  clip: initial;
  text-decoration: none;
  text-align: center;
  background-color: #0039a6;
  color: white;
}
.slideSkip:focus-visible,
.homeSkip:focus-visible,
.membershipSkip:focus-visible {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999999999999999;
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px;
  clip: initial;
  text-decoration: none;
  text-align: center;
  background-color: #0039a6;
  color: white;
}

[data-active-zone=home] .homeSkip {
  display: block;
}

[data-active-zone=slides] .slideSkip {
  display: block;
}

.membershipSkip {
  display: block;
}

@keyframes loadingScreenMascot {
  0% {
    top: 50%;
    transform: translate(-50%, 0) scale(1);
  }
  25% {
    top: 18%;
    transform: translate(-50%, 0) scale(1) rotate(0);
  }
  40% {
    top: 20%;
  }
  100% {
    top: 20%;
    transform: translate(-50%, 0) scale(1.2) rotate(15deg);
  }
}
.loadingScreen {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.loadingScreen .paw {
  width: 75%;
  height: 75%;
  background: url(/images/paw.svg) no-repeat;
  position: fixed;
  top: -100px;
  left: 50px;
  z-index: -1;
  transform: rotate(-15deg) scale(0.5);
  transition: 500ms all;
  opacity: 0;
}
.loadingScreen .paw.start {
  opacity: 1;
  transform: rotate(-15deg) scale(1);
}
.loadingScreen .topColor {
  content: "";
  background: #00aeef;
  position: fixed;
  height: 160%;
  width: 180%;
  z-index: -1;
  transform: rotate(-15deg);
  top: 75%;
  transition: 800ms all;
  opacity: 0;
}
.loadingScreen .topColor.start {
  opacity: 1;
  top: 115px;
}
.loadingScreen .topColor.start .line {
  width: 140%;
  opacity: 1;
}
.loadingScreen .bottomColor {
  content: "";
  background: #0039a6;
  position: fixed;
  bottom: -500px;
  height: 80%;
  width: 120%;
  z-index: 1;
  left: 0;
  opacity: 0;
  transform: rotate(0deg);
  transition: 500ms all;
}
.loadingScreen .bottomColor.start {
  bottom: -250px;
  opacity: 1;
  transform: rotate(-15deg);
}
.loadingScreen .bottomColor.start .text {
  clip-path: inset(-30% 0% 5% 0%);
}
.loadingScreen .loadingScreenMascot {
  position: fixed;
  width: 750px;
  height: auto;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0) rotate(5deg);
  transition: 500ms all;
  opacity: 0;
}
.loadingScreen .loadingScreenMascot.start {
  animation: loadingScreenMascot 3.5s linear forwards;
  opacity: 1;
}
.loadingScreen .line {
  position: fixed;
  top: -3px;
  margin-top: -10px;
  left: 0;
  width: 0;
  height: 7px;
  background: url(/images/dot.svg) repeat-x;
  transition: 1000ms all;
  transition-delay: 1.5s;
  z-index: 1;
  opacity: 0;
}
[data-reduce-animation=true] .loadingScreen .line {
  transition-delay: 0s !important;
  transition: none !important;
}
.loadingScreen .logoWrap {
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 3;
  opacity: 0;
  transition: 500ms all;
}
.loadingScreen .logoWrap .logo {
  height: 150px;
}
.loadingScreen .logoWrap.start {
  opacity: 1;
}
.loadingScreen .text {
  font-family: "proxima-nova", sans-serif;
  font-size: 4em;
  position: fixed;
  top: 20px;
  transform: rotate(0deg);
  z-index: 2;
  color: white;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding-right: 11vw;
  clip-path: inset(-30% 80% 5% 20%);
  transition: 2.5s all;
  transition-delay: 1.5s;
  line-height: 1.5;
}
[data-reduce-animation=true] .loadingScreen .text {
  transition-delay: 0s !important;
  transition: none !important;
}
.loadingScreen .text .inner {
  position: relative;
}
.loadingScreen .text .inner:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -25px;
  width: 75px;
  height: 60px;
  background: url(/images/quote.png) no-repeat;
  opacity: 0.125;
}
.loadingScreen .text .inner:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: -25px;
  width: 75px;
  height: 60px;
  background: url(/images/quote.png) no-repeat;
  opacity: 0.125;
  transform: rotate(180deg);
}
.loadingScreen .text .title {
  color: #00aeef;
}

@media (max-width: 1500px) {
  .loadingScreen .topColor.start {
    top: 165px;
  }
  .loadingScreen .text {
    font-size: 3.5em;
  }
}
@media (max-width: 1280px) {
  .loadingScreen .text {
    font-size: 2.75em;
  }
  .loadingScreen .paw {
    opacity: 0.75;
    width: 65%;
    height: 65%;
    top: 60px;
  }
  .loadingScreen .topColor.start {
    top: 220px;
  }
  .loadingScreen .bottomColor {
    height: 75%;
  }
  .loadingScreen .loadingScreenMascot {
    width: 650px;
    margin-top: 40px;
  }
  .loadingScreen .logoWrap .logo {
    height: 125px;
  }
}
@media (max-width: 850px) {
  html[data-dialog-hide-overflow=true]:not([data-active-dialog=toolsIcons]) {
    overflow: hidden !important;
  }
  iframe {
    border: none;
  }
  .loadingScreen .loadingScreenMascot {
    margin-top: 60px;
  }
  .loadingScreen .topColor.start {
    top: 360px;
  }
  .loadingScreen .text {
    font-size: 2.125em;
  }
  .loadingScreen .bottomColor {
    left: -50px;
    padding-left: 60px;
    width: 128%;
  }
  body {
    overflow: visible;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
  }
  [data-active-dialog=utilityMenu] body {
    overflow-x: visible;
  }
}
@media (max-height: 600px) and (max-width: 850px) and (orientation: landscape) {
  body {
    overflow-x: visible;
  }
}
@media (max-width: 600px) {
  .loadingScreen .loadingScreenMascot {
    margin-top: 80px;
  }
  .loadingScreen .text {
    font-size: 2em;
    max-width: 460px;
    margin: auto;
    left: 0;
    right: 0;
    padding-right: 0;
    line-height: 1.1;
    padding-top: 20px;
    text-align: center;
    transform: translateX(20px);
  }
}
@media (max-width: 480px) {
  .loadingScreen .paw {
    width: 85%;
    height: 85%;
    top: 120px;
  }
  .loadingScreen .logoWrap {
    bottom: 20px;
    right: 30px;
  }
  .loadingScreen .logoWrap .logo {
    height: 100px;
  }
  .loadingScreen .loadingScreenMascot {
    margin-top: 110px;
  }
  .loadingScreen .bottomColor {
    width: 136%;
  }
  .loadingScreen .text {
    font-size: 1.6em;
    max-width: 360px;
  }
}
@media (max-width: 400px) {
  .loadingScreen .loadingScreenMascot {
    margin-top: 130px;
  }
  .loadingScreen .bottomColor {
    width: 140%;
  }
  .loadingScreen .text {
    font-size: 1.4em;
    max-width: 330px;
    transform: translateX(40px);
  }
}
willis-zone {
  display: block;
}

[inert] {
  overflow: hidden;
  pointer-events: none;
}

willis-dialog:not([data-open=true]) {
  display: none !important;
}

.dialogBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slide .dialogBackdrop {
  height: 100%;
}

.dialogInner {
  position: relative;
}

[data-dialog-style=modal] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #444444 #f5f5f5;
  scrollbar-width: thin;
}
[data-dialog-style=modal]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
[data-dialog-style=modal]::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
[data-dialog-style=modal]::-webkit-scrollbar-thumb {
  background-color: #444444;
}
[data-dialog-style=modal] div[role=dialog] {
  width: 680px;
  margin: auto;
}
[data-dialog-style=modal] div[role=dialog].reportNeeded {
  width: 80%;
  max-width: 1000px;
}

[data-dialog-style=largeModal] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #444444 #f5f5f5;
  scrollbar-width: thin;
}
[data-dialog-style=largeModal]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
[data-dialog-style=largeModal]::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
[data-dialog-style=largeModal]::-webkit-scrollbar-thumb {
  background-color: #444444;
}
[data-dialog-style=largeModal] div[role=dialog] {
  width: 800px;
  max-width: 100vw;
  margin: auto;
}

[data-dialog-style=shade] {
  overflow-y: scroll;
}
[data-dialog-style=shade] div[role=dialog] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: #fff;
  color: black;
}
[data-dialog-style=shade] div[role=dialog] .dialogLabel {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogContent {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogActions {
  max-width: 800px;
}

[data-dialog-style^=sidebar] {
  display: flex;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] {
  justify-content: flex-start;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] {
  justify-content: flex-end;
}
[data-dialog-style^=sidebar] div[role=dialog] {
  width: 30%;
}

[data-dialog-style^=full] {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}
[data-dialog-style^=full] div[role=dialog] {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  [data-dialog-style^=sidebar] div[role=dialog] {
    width: 100%;
    min-width: 0;
  }
  [data-dialog-style=modal] div[role=dialog] {
    width: 100%;
  }
}
div[data-dialog-animation-speed=glacial] {
  animation-duration: 3000ms;
}

div[data-dialog-animation-speed=verySlow] {
  animation-duration: 1000ms;
}

div[data-dialog-animation-speed=slow] {
  animation-duration: 800ms;
}

div[data-dialog-animation-speed=medium] {
  animation-duration: 500ms;
}

div[data-dialog-animation-speed=fast] {
  animation-duration: 300ms;
}

div[data-dialog-animation-speed=veryFast] {
  animation-duration: 200ms;
}

.dialogBackdrop {
  animation-fill-mode: both;
  animation-duration: 200ms;
}
.dialogBackdrop.in {
  animation-name: fadeIn;
}
.dialogBackdrop.out {
  animation-name: fadeOut;
}

div[role=dialog] {
  position: relative;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
div[data-dialog-animation-name=fadeIn] {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
div[data-dialog-animation-name=fadeOut] {
  animation-name: fadeOut;
}

@keyframes fadeTopIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeTopIn] {
  animation-name: fadeTopIn;
}

@keyframes fadeTopOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
div[data-dialog-animation-name=fadeTopOut] {
  animation-name: fadeTopOut;
}

@keyframes fadeBottomIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeBottomIn] {
  animation-name: fadeBottomIn;
}

@keyframes fadeBottomOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(50%);
  }
}
div[data-dialog-animation-name=fadeBottomOut] {
  animation-name: fadeBottomOut;
}

@keyframes fadeRightIn {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeRightIn] {
  animation-name: fadeRightIn;
}

@keyframes fadeRightOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
div[data-dialog-animation-name=fadeRightOut] {
  animation-name: fadeRightOut;
}

@keyframes fadeLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeLeftIn] {
  animation-name: fadeLeftIn;
}

@keyframes fadeLeftOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}
div[data-dialog-animation-name=fadeLeftOut] {
  animation-name: fadeLeftOut;
}

@keyframes slideTopIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideTopIn] {
  animation-name: slideTopIn;
}
@media (max-width: 850px) {
  div[data-dialog-animation-name=slideTopIn] {
    animation-name: slideBottomIn;
  }
}

@keyframes slideTopOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
div[data-dialog-animation-name=slideTopOut] {
  animation-name: slideTopOut;
}

@keyframes slideBottomIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideBottomIn] {
  animation-name: slideBottomIn;
}

@keyframes slideBottomOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(150%);
  }
}
div[data-dialog-animation-name=slideBottomOut] {
  animation-name: slideBottomOut;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideRightIn] {
  animation-name: slideRightIn;
}

@keyframes slideRightOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
div[data-dialog-animation-name=slideRightOut] {
  animation-name: slideRightOut;
}

@keyframes slideLeftIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideLeftIn] {
  animation-name: slideLeftIn;
}

@keyframes slideLeftOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
div[data-dialog-animation-name=slideLeftOut] {
  animation-name: slideLeftOut;
}

.dialogBackdrop {
  background: rgba(0, 57, 166, 0.95);
}

.dialogContent {
  margin: 10px 0;
}

.dialogActions.generated button.closeDialog .icon:before {
  font-family: "georgiaState";
  content: "b";
}

button.closeDialog .icon:before {
  font-family: "georgiaState";
  content: "b";
}

.dialogLabel h2 {
  margin-bottom: 15px;
}

[data-dialog-style=modal] {
  padding: 20px;
}
.safari [data-dialog-style=modal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=modal] .dialogInner {
  border-radius: 0;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: black;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
}
[data-dialog-style=modal] button.closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
}
@media (hover: hover) and (pointer: fine) {
  [data-dialog-style=modal] button.closeDialog:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] [data-dialog-style=modal] button.closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie [data-dialog-style=modal] button.closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog[disabled], [data-dialog-style=modal] button.closeDialog[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
[data-dialog-style=modal] button.closeDialog .icon {
  display: none;
}

.safari [data-dialog-style=largeModal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=largeModal] .dialogInner {
  border-radius: 0;
  padding: 20px;
  background-color: #fff;
  color: black;
}
[data-dialog-style=largeModal] .dialogLabel {
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #0039a6;
  padding: 5px 10px 20px;
  text-transform: uppercase;
}
[data-dialog-style=largeModal] .dialogLabel h2 {
  margin: 0;
  padding: 0;
  font-size: 1.25em;
  color: #0039a6;
}

[data-dialog-style=shade] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}

[data-dialog-style^=sidebar] .dialogInner {
  min-height: 100%;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}

[data-dialog-style^=full] .dialogInner {
  padding: 20px;
}

.simpleDialog {
  background-color: #fff;
  color: black;
  border-radius: 0;
}

@media (max-width: 850px) {
  [data-dialog-style=modal] div[role=dialog].reportNeeded {
    width: 100%;
  }
  [data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
    border-radius: 0;
  }
  [data-dialog-style=modal] div[role=dialog] {
    width: 100%;
  }
  [data-dialog-style=largeModal] .dialogInner {
    margin: 0 20px;
  }
}
@media (max-width: 600px) {
  [data-dialog-style=modal] div[role=dialog].reportNeeded {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .safari [data-dialog-style=modal][data-backdrop-for=accessibilityPrefs] {
    padding: 20px 20px 150px;
  }
}
@keyframes zoomIn {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.3);
  }
}
@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: 500ms opacity;
}
.menuOut #background:after {
  transform: skew(15deg);
  right: -250px;
  width: 150%;
}
[data-is-loading=true] #background {
  opacity: 0;
}
#background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  left: auto;
  right: 320px;
  height: 100%;
  width: 100%;
  background: #0039a6;
  mix-blend-mode: multiply;
  z-index: 2;
  transform: skew(-15deg);
  transition: 1000ms all;
}
[data-is-loading=true] #background:after {
  right: -200px;
  width: 150%;
}
.minimized #background:after {
  width: 100px;
}
.toolsMode #background:after {
  width: 100%;
}
.menuOut #background:after {
  width: 150%;
  transform: skew(-15deg);
}
#background:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  right: -200px;
  width: 0;
  transform: skew(-15deg);
  background: #fff;
  z-index: 3;
  transition: 800ms all;
}
.menuOut #background:before {
  width: 70%;
}
.menuOut.centeredSlide #background:before {
  width: 100%;
}
#background .cover-slider,
#background video,
#background .mainBgImg {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#background .sectionBgHolder {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.menuOut #background .sectionBgHolder {
  z-index: 3;
}
#background .sectionBgHolder[data-section-bg-only=true] {
  opacity: 0;
  transition: opacity 0.5s;
}
.inSection #background .sectionBgHolder[data-section-bg-only=true] {
  opacity: 1;
}
#background .bgImg,
#background .sectionBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
#background .bgImg[data-bg-pos="top left"],
#background .sectionBg[data-bg-pos="top left"] {
  background-position: 0% 0%;
}
#background .bgImg[data-bg-pos="top middle"],
#background .sectionBg[data-bg-pos="top middle"] {
  background-position: 50% 0;
}
#background .bgImg[data-bg-pos="top right"],
#background .sectionBg[data-bg-pos="top right"] {
  background-position: 100% 0%;
}
#background .bgImg[data-bg-pos="center left"],
#background .sectionBg[data-bg-pos="center left"] {
  background-position: 0% 50%;
}
#background .bgImg[data-bg-pos=center],
#background .sectionBg[data-bg-pos=center] {
  background-position: 50% 50%;
}
#background .bgImg[data-bg-pos="center right"],
#background .sectionBg[data-bg-pos="center right"] {
  background-position: 100% 50%;
}
#background .bgImg[data-bg-pos="bottom left"],
#background .sectionBg[data-bg-pos="bottom left"] {
  background-position: 0% 100%;
}
#background .bgImg[data-bg-pos="bottom middle"],
#background .sectionBg[data-bg-pos="bottom middle"] {
  background-position: 50% 100%;
}
#background .bgImg[data-bg-pos="bottom right"],
#background .sectionBg[data-bg-pos="bottom right"] {
  background-position: 100% 100%;
}
#background [data-slideshow=zoomSlideshow].returnPause .bgImgWrapper:first-child {
  opacity: 1;
}
#background [data-slideshow=zoomSlideshow] .bgImgWrapper {
  opacity: 0;
  animation-duration: 0.85s;
  animation-timing-function: linear;
}
#background [data-slideshow=zoomSlideshow] .bgImgWrapper.current {
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  z-index: 1;
}
#background [data-slideshow=zoomSlideshow] .bgImgWrapper.prev {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}
#background [data-slideshow=zoomSlideshow] .bgImgWrapper:nth-child(odd) .bgImg.trans {
  animation-name: zoomIn;
}
#background [data-slideshow=zoomSlideshow] .bgImgWrapper:nth-child(even) .bgImg.trans {
  animation-name: zoomOut;
}
#background [data-slideshow=zoomSlideshow] .bgImg {
  transform: scale(1.2);
  z-index: 1;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos=center] {
  background-position: 50% 50%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="center left"] {
  background-position: 10% 50%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="center right"] {
  background-position: 90% 50%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="top left"] {
  background-position: 10% 10%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="top middle"] {
  background-position: 50% 10%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="top right"] {
  background-position: 90% 10%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="bottom left"] {
  background-position: 10% 90%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="bottom middle"] {
  background-position: 50% 100%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-bg-pos="bottom left"] {
  background-position: 90% 90%;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-animation=center] {
  transform-origin: center center;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-animation="top right"] {
  transform-origin: top right;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-animation="top left"] {
  transform-origin: top left;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-animation="bottom right"] {
  transform-origin: bottom right;
}
#background [data-slideshow=zoomSlideshow] .bgImg[data-animation="bottom left"] {
  transform-origin: bottom left;
}
#background [data-slideshow=zoomSlideshow].paused .bgImgWrapper.trans {
  animation-play-state: paused;
}
#background [data-slideshow=zoomSlideshow].paused .bgImg.trans {
  animation-play-state: paused;
}
#background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #eeeeee;
  transition: 800ms all;
}
#backgroundPlayBtn,
#backgroundPlayBtnMobile {
  position: fixed;
  bottom: 30px;
  right: calc(305px + 16.5vh);
  width: 32px;
  height: 32px;
  color: #fff;
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 1.5em;
  transition: 300ms all;
  cursor: pointer;
  transition: all 200ms;
}
@media (hover: hover) and (pointer: fine) {
  #backgroundPlayBtn:hover .text,
  #backgroundPlayBtnMobile:hover .text {
    opacity: 1;
    visibility: visible;
  }
}
[data-browser=ie] #backgroundPlayBtn:hover .text,
[data-browser=ie] #backgroundPlayBtnMobile:hover .text {
  opacity: 1;
  visibility: visible;
}
.ie #backgroundPlayBtn:hover .text,
.ie #backgroundPlayBtnMobile:hover .text {
  opacity: 1;
  visibility: visible;
}
@media (hover: hover) and (pointer: fine) {
  #backgroundPlayBtn:hover,
  #backgroundPlayBtnMobile:hover {
    transform: scale(1.1);
  }
}
[data-browser=ie] #backgroundPlayBtn:hover,
[data-browser=ie] #backgroundPlayBtnMobile:hover {
  transform: scale(1.1);
}
.ie #backgroundPlayBtn:hover,
.ie #backgroundPlayBtnMobile:hover {
  transform: scale(1.1);
}
#backgroundPlayBtn.focus-visible,
#backgroundPlayBtnMobile.focus-visible {
  transform: scale(1.1);
}
#backgroundPlayBtn:focus-visible,
#backgroundPlayBtnMobile:focus-visible {
  transform: scale(1.1);
}
#backgroundPlayBtn:focus-visible .text,
#backgroundPlayBtnMobile:focus-visible .text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#backgroundPlayBtn .text,
#backgroundPlayBtnMobile .text {
  font-size: 0.55em;
  position: absolute;
  top: calc(100% - 3px);
  right: 29px;
  transform: translate(50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: 300ms all;
  text-transform: uppercase;
}
.toolsMode #backgroundPlayBtn,
.toolsMode #backgroundPlayBtnMobile {
  opacity: 0;
  pointer-events: none;
}
[data-active-dialog=checklistAndAnnouncements] #backgroundPlayBtn, [data-active-dialog=faq] #backgroundPlayBtn, [data-active-dialog=glossary] #backgroundPlayBtn, [data-active-dialog=achievements] #backgroundPlayBtn, [data-active-dialog=resources] #backgroundPlayBtn, [data-active-dialog=faqConfirmQuestionMessage] #backgroundPlayBtn, [data-active-dialog=faqQuestionSubmittedMessage] #backgroundPlayBtn,
[data-active-dialog=checklistAndAnnouncements] #backgroundPlayBtnMobile,
[data-active-dialog=faq] #backgroundPlayBtnMobile,
[data-active-dialog=glossary] #backgroundPlayBtnMobile,
[data-active-dialog=achievements] #backgroundPlayBtnMobile,
[data-active-dialog=resources] #backgroundPlayBtnMobile,
[data-active-dialog=faqConfirmQuestionMessage] #backgroundPlayBtnMobile,
[data-active-dialog=faqQuestionSubmittedMessage] #backgroundPlayBtnMobile {
  display: none;
}
[data-active-dialog=utilityMenu] #backgroundPlayBtn,
[data-active-dialog=utilityMenu] #backgroundPlayBtnMobile {
  display: none;
}
[data-active-zone=slides] #backgroundPlayBtn,
[data-active-zone=slides] #backgroundPlayBtnMobile {
  display: none;
}
[data-is-loading=true] #backgroundPlayBtn,
[data-is-loading=true] #backgroundPlayBtnMobile {
  opacity: 0 !important;
}
#backgroundPlayBtn .icon-play,
#backgroundPlayBtn .text.play,
#backgroundPlayBtnMobile .icon-play,
#backgroundPlayBtnMobile .text.play {
  display: none;
}
#backgroundPlayBtn.pause .icon-play,
#backgroundPlayBtn.pause .text.play,
#backgroundPlayBtnMobile.pause .icon-play,
#backgroundPlayBtnMobile.pause .text.play {
  display: block;
}
#backgroundPlayBtn.pause .icon-pause,
#backgroundPlayBtn.pause .text.pause,
#backgroundPlayBtnMobile.pause .icon-pause,
#backgroundPlayBtnMobile.pause .text.pause {
  display: none;
}

#backgroundPlayBtnMobile {
  display: none;
}

@media (max-width: 1500px) {
  .minimized #background::after {
    width: 90px;
  }
}
@media (max-width: 1280px) {
  #background::after {
    right: 248px;
  }
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    font-size: 1.25em;
    right: calc(235px + 16.5vh);
  }
  .menuOut #background:before {
    width: 80%;
  }
  .menuOut.centeredSlide #background::before {
    width: 150%;
  }
}
@media (max-width: 1050px) {
  .minimized #background::after {
    width: 80px;
  }
  .menuOut #background::before {
    width: calc(100% + 320px);
    right: -180px;
  }
}
@media (min-width: 851px) and (max-width: 900px) and (max-height: 480px) {
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    bottom: 100px;
  }
}
@media (max-width: 850px) {
  #backgroundPlayBtnMobile {
    display: block;
  }
  #backgroundPlayBtn {
    display: none;
  }
  .minimized #background::after {
    width: 0;
  }
  .menuOut.centeredSlide #background::before {
    width: calc(100% + 320px);
  }
  #background {
    width: 100%;
    height: 100%;
  }
  #background:after {
    transform: none;
    right: 0;
  }
}
@media (max-width: 480px) {
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    font-size: 1.125em;
  }
}
.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password {
  position: relative;
  max-width: 500px;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel) {
  line-height: 1;
  white-space: normal;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]) {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
  min-width: 100%;
  max-width: 100%;
  max-height: 120px;
}
.fieldWrapper input::-ms-reveal,
.fieldWrapper input::-ms-clear,
.umbraco-forms-field.shortanswer input::-ms-reveal,
.umbraco-forms-field.shortanswer input::-ms-clear,
.umbraco-forms-field.date input::-ms-reveal,
.umbraco-forms-field.date input::-ms-clear,
.umbraco-forms-field.password input::-ms-reveal,
.umbraco-forms-field.password input::-ms-clear {
  display: none;
}
.fieldWrapper .description,
.umbraco-forms-field.shortanswer .description,
.umbraco-forms-field.date .description,
.umbraco-forms-field.password .description {
  margin-top: 6px;
  font-size: 0.84em;
  color: rgba(63, 63, 63, 0.85);
}
.fieldWrapper .description > *:first-child,
.umbraco-forms-field.shortanswer .description > *:first-child,
.umbraco-forms-field.date .description > *:first-child,
.umbraco-forms-field.password .description > *:first-child {
  margin-top: 0 !important;
}
.fieldWrapper .description > *:last-child,
.umbraco-forms-field.shortanswer .description > *:last-child,
.umbraco-forms-field.date .description > *:last-child,
.umbraco-forms-field.password .description > *:last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper textarea,
.umbraco-forms-field.longanswer textarea {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
  min-width: 100%;
  max-width: 100%;
  max-height: 120px;
}

.dropdownWrapper,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}
.dropdownWrapper select,
.umbraco-forms-field.dropdown select {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
  min-width: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 25px 50px;
}
.dropdownWrapper select::-ms-expand,
.umbraco-forms-field.dropdown select::-ms-expand {
  display: none;
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.controlWrapper:last-child,
.adg-umbraco-forms-controlwrapper:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.controlWrapper > *:first-child,
.adg-umbraco-forms-controlwrapper > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper > *:last-child,
.adg-umbraco-forms-controlwrapper > *:last-child {
  margin-bottom: 0 !important;
}
.controlWrapper .control,
.adg-umbraco-forms-controlwrapper .control {
  display: flex;
  align-items: center;
}
.controlWrapper label,
.adg-umbraco-forms-controlwrapper label {
  font-size: 1.2em;
  white-space: nowrap;
  cursor: pointer;
}
.controlWrapper .description,
.adg-umbraco-forms-controlwrapper .description {
  margin-top: 6px;
  font-size: 0.84em;
  color: rgba(63, 63, 63, 0.85);
}
.controlWrapper .description > *:first-child,
.adg-umbraco-forms-controlwrapper .description > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper .description > *:last-child,
.adg-umbraco-forms-controlwrapper .description > *:last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper.enrolleeName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  padding: 30px 20px;
  border: none;
  border-top: 5px solid #0039a6;
  box-shadow: 0 2px 4px #eeeeee;
  background: #fff;
}
.styledFieldset legend,
.styledFieldset .groupLegend,
.umbraco-forms-fieldset legend,
.umbraco-forms-fieldset .groupLegend {
  display: inline-block;
  padding: 4px 8px;
  background-color: #333;
  color: white;
}
.styledFieldset .groupLegend,
.umbraco-forms-fieldset .groupLegend {
  display: inline-block;
  margin-bottom: 9px;
}
.styledFieldset .fieldsetGrid,
.umbraco-forms-fieldset .fieldsetGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.styledFieldset .fieldsetGrid .fieldWrapper,
.styledFieldset .fieldsetGrid .controlWrapper,
.umbraco-forms-fieldset .fieldsetGrid .fieldWrapper,
.umbraco-forms-fieldset .fieldsetGrid .controlWrapper {
  margin: 0;
}
.styledFieldset > :last-child,
.umbraco-forms-fieldset > :last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password {
  margin: 10px auto;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  margin: 0 0 5px;
  border: 0;
  padding: 3px 8px;
  font-size: 0.8em;
  background-color: #cccccc;
  color: black;
  font-family: "Arial", sans-serif;
  font-weight: 600;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.fieldWrapper textarea,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer textarea,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date textarea,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password textarea {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 120px;
  min-height: 48px;
  margin: 0;
  border: 1px solid #cccccc;
  padding: 23px 8px 6px;
  border-radius: 0;
  background: #fff;
  color: #3f3f3f;
  text-align: left;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]):focus-visible label,
.fieldWrapper textarea:focus-visible label,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]):focus-visible label,
.umbraco-forms-field.shortanswer textarea:focus-visible label,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]):focus-visible label,
.umbraco-forms-field.date textarea:focus-visible label,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]):focus-visible label,
.umbraco-forms-field.password textarea:focus-visible label {
  background-color: #0039a6;
  color: white;
}
.fieldWrapper button,
.umbraco-forms-field.shortanswer button,
.umbraco-forms-field.date button,
.umbraco-forms-field.password button {
  position: absolute !important;
  color: #fff;
  top: 0;
  right: 0;
  height: 53px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: scale(0.8);
  border-radius: 0;
  background: #0039a6;
  border: none;
  padding: 0;
  cursor: pointer;
}
.fieldWrapper button[aria-checked=true] .icon:after,
.umbraco-forms-field.shortanswer button[aria-checked=true] .icon:after,
.umbraco-forms-field.date button[aria-checked=true] .icon:after,
.umbraco-forms-field.password button[aria-checked=true] .icon:after {
  content: "";
  width: 3px;
  height: 20px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  transform: skewX(-45deg);
  background: #fff;
}
.fieldWrapper button .icon,
.umbraco-forms-field.shortanswer button .icon,
.umbraco-forms-field.date button .icon,
.umbraco-forms-field.password button .icon {
  font-size: 1.2em;
}
.fieldWrapper #Password:focus + #requirements,
.umbraco-forms-field.shortanswer #Password:focus + #requirements,
.umbraco-forms-field.date #Password:focus + #requirements,
.umbraco-forms-field.password #Password:focus + #requirements {
  max-height: 1000px;
}
.fieldWrapper #requirements,
.umbraco-forms-field.shortanswer #requirements,
.umbraco-forms-field.date #requirements,
.umbraco-forms-field.password #requirements {
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s ease-in-out;
}
.fieldWrapper #requirements li,
.umbraco-forms-field.shortanswer #requirements li,
.umbraco-forms-field.date #requirements li,
.umbraco-forms-field.password #requirements li {
  display: flex;
  align-items: center;
}
.fieldWrapper #requirements li.valid .icon,
.umbraco-forms-field.shortanswer #requirements li.valid .icon,
.umbraco-forms-field.date #requirements li.valid .icon,
.umbraco-forms-field.password #requirements li.valid .icon {
  background-color: #542e8e;
  color: white;
  font-size: 0.9em;
}
.fieldWrapper #requirements li.valid .icon:before,
.umbraco-forms-field.shortanswer #requirements li.valid .icon:before,
.umbraco-forms-field.date #requirements li.valid .icon:before,
.umbraco-forms-field.password #requirements li.valid .icon:before {
  content: "a";
}
.fieldWrapper #requirements li .icon,
.umbraco-forms-field.shortanswer #requirements li .icon,
.umbraco-forms-field.date #requirements li .icon,
.umbraco-forms-field.password #requirements li .icon {
  background-color: #cc0000;
  color: white;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 0.55em;
}

.dropdownWrapper label:not(.checkboxLabel),
.umbraco-forms-field.dropdown label:not(.checkboxLabel) {
  position: absolute;
  top: 8px;
  left: 9px;
  z-index: 2;
  display: block;
  margin: 0 0 5px;
  border: 0;
  padding: 0;
  font-size: 0.8em;
  font-family: "Oswald", sans-serif;
  color: #3f3f3f;
}
.dropdownWrapper select,
.umbraco-forms-field.dropdown select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 52px;
  margin: 0;
  border: 2px solid #cccccc;
  padding: 26px 45px 8px 8px;
  border-radius: 0;
  background-color: #fff;
  text-align: left;
}

.fieldWrapper textarea,
.umbraco-forms-field.longanswer textarea {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 120px;
  min-height: 48px;
  margin: 0;
  border: 2px solid #cccccc;
  padding: 6px 8px 6px;
  border-radius: 0;
  background: #fff;
  color: #3f3f3f;
  text-align: left;
}

form .error,
.formWrap .error {
  display: block;
  margin: 4px 0;
  padding: 2px 4px;
  text-align: center;
  background-color: #cc0000;
  color: white;
}
form .error:empty,
.formWrap .error:empty {
  display: none;
}

@media (max-width: 600px) {
  .styledFieldset,
  .umbraco-forms-fieldset {
    padding: 10px;
  }
  .styledFieldset .fieldsetGrid,
  .umbraco-forms-fieldset .fieldsetGrid {
    grid-template-columns: none;
  }
}
@media (max-width: 480px) {
  .controlWrapper label {
    font-size: 1em;
    font-weight: 700;
    white-space: normal;
  }
}
@media (max-width: 400px) {
  .controlWrapper label {
    font-size: 0.9em;
  }
}
.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;
  z-index: 99;
}
footer .adgLogoWrap a {
  display: block;
  background: #000 !important;
}
footer .adgLogoWrap .adgLogo {
  height: 30px;
  width: auto;
}

.guestLoginMethods .socialMediaTitle {
  margin-bottom: 15px;
  font-weight: 400;
}
.guestLoginMethods .socialMediaLogins {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
}
.guestLoginMethods .socialMediaLogins .facebookLogin {
  background: #1877f2 !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins .googleLogin {
  background: #ebebec !important;
  color: black !important;
}
.guestLoginMethods .socialMediaLogins .twitterLogin {
  background: #14171a !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins .twitterLogin .text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.guestLoginMethods .socialMediaLogins .appleLogin {
  background: black !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins > a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none !important;
  border-radius: 0;
  height: 45px;
  text-align: center;
  padding: 1em !important;
}
.guestLoginMethods .socialMediaLogins > a .svgIcon {
  width: 16px;
  height: auto;
  margin-right: 7px;
}
.guestLoginMethods .socialMediaLogins > a .text {
  font-weight: bold;
  font-size: 1.1em;
}
.guestLoginMethods .socialMediaLogins .appleLogin {
  height: 45px;
  cursor: pointer;
}
.guestLoginMethods .socialMediaLogins .appleLogin > div {
  max-width: 100% !important;
}

.loginWrapper[data-hidden-for-guest=true] {
  display: none !important;
}

.guestWrapperHolder {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 800ms;
  text-align: left;
}

.fieldWrapper.enrolleeName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

body {
  overflow-x: hidden;
}

@keyframes borderRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#backgroundPlayBtn {
  right: auto;
  bottom: auto;
  top: 20px;
  left: 20px;
}
#backgroundPlayBtn .text {
  font-size: 0.5em;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  transform: none;
}

#background {
  width: 50%;
}
#background:before {
  width: 70%;
  position: fixed;
}
#background:after {
  width: 120%;
  right: 100px;
}

#mainImg {
  position: fixed;
  z-index: 10;
  top: 80px;
  left: 80px;
  height: calc(100% - 160px);
  width: 100%;
  max-width: 45%;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-size: cover;
  cursor: move;
  outline: 0px solid #fff;
  transition: width 300ms, height 300ms, outline 300ms, min-height 300ms;
}
#mainImg .icon {
  display: none;
}

.orientationTitle {
  position: fixed;
  z-index: 2;
  right: 260px;
  bottom: 20px;
  text-align: right;
}
.orientationTitle.guest {
  bottom: 50px;
  right: 250px;
}

.branding {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 40px;
  background-size: cover;
  transition: 500ms all;
  z-index: 100;
  width: 250px;
  right: -40px;
}
.branding:before {
  transform: skew(-15.1deg);
  background: #0039a6;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  height: 100%;
  content: "";
}
.branding .logo {
  height: 110px;
  z-index: 10;
  position: relative;
  bottom: 0;
  right: 0;
}

#membership {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 200px);
  max-width: 41%;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 160px;
  z-index: 5;
  width: 100%;
  right: 5%;
  top: 8%;
}
#membership .titleArea {
  margin: 0;
  font-size: 2.5em;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: #0039a6;
  padding-bottom: 25px;
  margin-bottom: 10px;
  position: relative;
}
#membership .titleArea:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 7px;
  width: calc(100% + 150px);
  background: url(/images/dot-for-slide-nav-blue.svg) repeat-x;
}
#membership .titleArea h2 {
  margin: 0;
}
#membership .titleArea p {
  margin: 0;
}
#membership button.returnToSocial {
  display: none;
  position: relative;
  padding: 5px;
  margin: 25px 0 0;
  border: 0;
  background: none;
  color: #0039a6;
  cursor: pointer;
  transition: all 200ms;
}
#membership button.returnToSocial .text {
  font-size: 0.9em;
  text-decoration: underline;
}
#membership button.returnToSocial .icon {
  position: relative;
  font-size: 0.8em;
  transition: all 500ms;
}
@media (hover: hover) and (pointer: fine) {
  #membership button.returnToSocial:hover .text {
    color: #00aeef;
  }
  #membership button.returnToSocial:hover .icon {
    margin-right: 3px;
  }
}
[data-browser=ie] #membership button.returnToSocial:hover .text {
  color: #00aeef;
}
[data-browser=ie] #membership button.returnToSocial:hover .icon {
  margin-right: 3px;
}
.ie #membership button.returnToSocial:hover .text {
  color: #00aeef;
}
.ie #membership button.returnToSocial:hover .icon {
  margin-right: 3px;
}
#membership button.returnToSocial.focus-visible .text {
  color: #00aeef;
}
#membership button.returnToSocial.focus-visible .icon {
  margin-right: 3px;
}
#membership button.returnToSocial:focus-visible .text {
  color: #00aeef;
}
#membership button.returnToSocial:focus-visible .icon {
  margin-right: 3px;
}
#membership .errorMessage,
#membership .field-validation-error {
  display: inline-block;
  text-align: center;
  background-color: #cc0000;
  color: white;
  max-width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  margin: 2px auto 10px;
  border-radius: 0;
}
#membership .loginNav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  padding-top: 10px;
  text-align: center;
  font-size: 0.9em;
}
#membership .loginNav > * {
  margin: 0 10px;
}
#membership .fieldWrap {
  padding: 30px 20px;
  border-top: 5px solid #0039a6;
  box-shadow: 0 2px 4px #eeeeee;
}
#membership .formWrap {
  margin-top: 10px;
  padding: 25px 40px;
  text-align: center;
  background-color: #fff;
  color: black;
}
#membership .formWrap p {
  font-size: 1.2em;
}
#membership .formWrap input[type=radio] {
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=radio] {
    appearance: none;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
  }
  #membership .formWrap input[type=radio]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 2px;
    border-color: #3f3f3f;
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:checked:before {
    border-color: #0039a6;
    background-color: #0039a6;
  }
  #membership .formWrap input[type=radio]:checked:after {
    opacity: 1;
    background-color: white;
    width: 45%;
    height: 45%;
  }
  #membership .formWrap input[type=radio][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=radio][disabled]:before {
    background-color: #fefefe;
  }
}
#membership .formWrap input[type=checkbox] {
  position: relative;
  margin: 0;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=checkbox] {
    appearance: none;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
  }
  #membership .formWrap input[type=checkbox]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    border-color: #3f3f3f;
    transition: all 200ms;
  }
  #membership .formWrap input[type=checkbox]:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: transparent url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9 IjAgMCAzMTYuMzggMjUwLjYiPjxwYXRoIGQ9Ik03MC40IDIxOS44YzQuNTUgNC44 NyA5LjIyIDkuNjcgMTQuMTIgMTQuMTkgMy43NSAzLjQ3IDcuNzEgNi44NSAxMS45 MyA5Ljc0IDUuNSAzLjc4IDExLjkyIDcuMDggMTguNzggNi44NSA2LjY2LS4yMyAx Mi43Ny0zLjczIDE3Ljk3LTcuNjIgOC42LTYuNDIgMTYuNjctMTUuMTcgMjMuODQt MjMuMTRMMzA1LjY4IDU0LjY5YzcuMi04IDExLjc5LTE4LjI1IDEwLjQ3LTI5LjE3 LTEuMDQtOC41OS01LjcxLTE2LjU1LTEzLjAxLTIxLjI5LTUuNjUtMy42Ny0xMi4y Ni00Ljk4LTE4LjktMy44Mi04LjkgMS41Ni0xNi41NCA3LjIyLTIyLjQ4IDEzLjc4 LTQ4LjM0IDUzLjMyLTk2LjU5IDEwNi43MS0xNDQuODUgMTYwLjExbC0zLjE5IDMu NTMtNjAuNC02Ni42M2MtOC40Mi05LjI5LTE5LjctMTMuOTQtMzEuOTItOS41Ny00 LjcyIDEuNjktOC45OSA0LjU4LTEyLjQ1IDguMTktMy44NiA0LjA0LTYuNzggOS4w Ni04LjEzIDE0LjQ5LTIuMzEgOS4yNy4zIDE4LjA3IDYuNjIgMjUuMTNsNjIuOTUg NzAuMzVaIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4K") no-repeat;
    background-size: 70%;
    background-position: 50% 50%;
    transition: all 200ms;
    opacity: 0;
    filter: invert(1);
  }
  #membership .formWrap input[type=checkbox]:checked:before {
    border-color: #0039a6;
    background-color: #0039a6;
  }
  #membership .formWrap input[type=checkbox]:checked:after {
    opacity: 1;
  }
  #membership .formWrap input[type=checkbox][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=checkbox][disabled]:before {
    background-color: rgba(63, 63, 63, 0.8);
  }
}
#membership .formWrap input[type=checkbox] ~ label,
#membership .formWrap input[type=radio] ~ label {
  padding-left: 0.35em;
}
#membership .formWrap.login .statusMessage {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  width: fit-content;
  margin: 10px auto;
}
#membership .formWrap.login .horzDivider {
  display: flex;
  align-items: center;
  margin: 35px 0;
}
#membership .formWrap.login .horzDivider .line {
  height: 2px;
  flex-grow: 1;
  border-top: 3px dotted #cccccc;
}
#membership .formWrap.login .horzDivider .or {
  text-transform: uppercase;
  color: #3f3f3f;
  margin-inline: 15px;
}
#membership .formWrap.login button.continueWithEmail {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
  width: 100%;
  flex-direction: column;
  padding: 1.3em 1.8em;
  transform: none !important;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.login button.continueWithEmail:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.login button.continueWithEmail:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #membership .formWrap.login button.continueWithEmail:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail[disabled], #membership .formWrap.login button.continueWithEmail[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .formWrap.login button.continueWithEmail .icon {
  font-size: 2.5em;
  margin-bottom: 10px;
}
#membership .formWrap.login button.continueWithEmail .text {
  font-size: 1.2em;
  font-weight: bold;
}
#membership .formWrap.login .loginWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 8px;
}
#membership .formWrap.login .returningWrapper {
  width: calc(60% - 20px);
  text-align: center;
}
#membership .formWrap.login .newAccountWrapper {
  width: 40%;
}
#membership .formWrap.login .newAccountWrapper > a:first-child {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child[disabled], #membership .formWrap.login .newAccountWrapper > a:first-child[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .formWrap.login .newAccountWrapper > a:first-child[data-type=Student] .icon:before {
  text-transform: lowercase;
  content: "z";
}
#membership .formWrap.login .newAccountWrapper > a:first-child .icon {
  font-size: 2.5em;
  margin-bottom: 10px;
}
#membership .formWrap.login .newAccountWrapper > a:first-child .text {
  font-weight: bold;
  font-size: 1.15em;
}
#membership .formWrap.landingPage {
  padding: 0;
}
#membership .formWrap.landingPage p {
  margin-bottom: 30px;
  font-size: 1.2em;
}
#membership .formWrap.landingPage .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0 100px;
}
#membership .formWrap.landingPage .buttons a {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: #767679;
  cursor: pointer;
  transition: 300ms all;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-direction: column;
  position: relative;
}
#membership .formWrap.landingPage .buttons a:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border: 3px dotted #cc0000;
  border-radius: 50%;
  z-index: -1;
  transition: 300ms all;
}
#membership .formWrap.landingPage .buttons a:after {
  content: "";
  width: 14px;
  height: 71px;
  position: absolute;
  left: -10px;
  bottom: -70px;
  background: url(/images/curved-arrow.png) no-repeat;
  background-position: center;
  transform: rotate(10deg);
  transform-origin: bottom;
  clip-path: inset(95% 20% 0% 10%);
  transition: 500ms all;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.landingPage .buttons a:hover {
    transform: scale(1.1);
    background: #0039a6;
  }
  #membership .formWrap.landingPage .buttons a:hover:before {
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    opacity: 1;
    animation: borderRotate 2s linear infinite;
  }
  #membership .formWrap.landingPage .buttons a:hover:after {
    clip-path: inset(5% 20% 15% 10%);
  }
  #membership .formWrap.landingPage .buttons a:hover .icon {
    transform: scale(1.125) rotate(-5deg);
  }
  #membership .formWrap.landingPage .buttons a:hover .text {
    top: calc(100% + 35px);
  }
}
[data-browser=ie] #membership .formWrap.landingPage .buttons a:hover {
  transform: scale(1.1);
  background: #0039a6;
}
[data-browser=ie] #membership .formWrap.landingPage .buttons a:hover:before {
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  animation: borderRotate 2s linear infinite;
}
[data-browser=ie] #membership .formWrap.landingPage .buttons a:hover:after {
  clip-path: inset(5% 20% 15% 10%);
}
[data-browser=ie] #membership .formWrap.landingPage .buttons a:hover .icon {
  transform: scale(1.125) rotate(-5deg);
}
[data-browser=ie] #membership .formWrap.landingPage .buttons a:hover .text {
  top: calc(100% + 35px);
}
.ie #membership .formWrap.landingPage .buttons a:hover {
  transform: scale(1.1);
  background: #0039a6;
}
.ie #membership .formWrap.landingPage .buttons a:hover:before {
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  animation: borderRotate 2s linear infinite;
}
.ie #membership .formWrap.landingPage .buttons a:hover:after {
  clip-path: inset(5% 20% 15% 10%);
}
.ie #membership .formWrap.landingPage .buttons a:hover .icon {
  transform: scale(1.125) rotate(-5deg);
}
.ie #membership .formWrap.landingPage .buttons a:hover .text {
  top: calc(100% + 35px);
}
#membership .formWrap.landingPage .buttons a.focus-visible {
  transform: scale(1.1);
  background: #0039a6;
}
#membership .formWrap.landingPage .buttons a.focus-visible:before {
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  animation: borderRotate 2s linear infinite;
}
#membership .formWrap.landingPage .buttons a.focus-visible:after {
  clip-path: inset(5% 20% 15% 10%);
}
#membership .formWrap.landingPage .buttons a.focus-visible .icon {
  transform: scale(1.125) rotate(-5deg);
}
#membership .formWrap.landingPage .buttons a.focus-visible .text {
  top: calc(100% + 35px);
}
#membership .formWrap.landingPage .buttons a:focus-visible {
  transform: scale(1.1);
  background: #0039a6;
}
#membership .formWrap.landingPage .buttons a:focus-visible:before {
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  animation: borderRotate 2s linear infinite;
}
#membership .formWrap.landingPage .buttons a:focus-visible:after {
  clip-path: inset(5% 20% 15% 10%);
}
#membership .formWrap.landingPage .buttons a:focus-visible .icon {
  transform: scale(1.125) rotate(-5deg);
}
#membership .formWrap.landingPage .buttons a:focus-visible .text {
  top: calc(100% + 35px);
}
#membership .formWrap.landingPage .buttons .text {
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  color: #0039a6;
  width: 100%;
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
  transition: 300ms all;
  pointer-events: none;
}
#membership .formWrap.landingPage .buttons .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 40px;
  width: 40px;
  font-family: "georgiaState";
  font-size: 6em;
  transition: 500ms all;
  color: #fff;
}
#membership .formWrap.landingPage .buttons .guestLoginBtn .icon:before {
  content: "k";
}
#membership .formWrap.landingPage .buttons .enrolleeLoginBtn .icon:before {
  content: "W";
}
#membership .formWrap.accountProfiler {
  text-align: left;
}
#membership .formWrap.accountProfiler .profileSettingsExplainer,
#membership .formWrap.accountProfiler .profileErrorWrap {
  text-align: center;
}
#membership .formWrap.accountProfiler .instruction {
  margin-bottom: 15px;
  text-align: center;
}
#membership .formWrap.accountProfiler .buttonHolder {
  margin: 20px 0 20px;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn {
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  overflow: visible;
  font-size: 0.8em;
  border-bottom: 1px solid #0039a6;
  cursor: pointer;
  color: #0039a6;
  background-color: white;
  transition: 200ms;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
    border-color: #00aeef;
    color: #00aeef;
    background-color: black;
  }
}
[data-browser=ie] #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #00aeef;
  color: #00aeef;
  background-color: black;
}
.ie #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #00aeef;
  color: #00aeef;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn.focus-visible {
  border-color: #00aeef;
  color: #00aeef;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn:focus-visible {
  border-color: #00aeef;
  color: #00aeef;
  background-color: black;
}
#membership .formWrap.twoFactor .singleFieldSubmission {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
#membership .formWrap.twoFactor .singleFieldSubmission .fieldWrapper {
  margin: 0 5px 0 0;
}
#membership .formWrap.twoFactor .singleFieldSubmission button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button[disabled], #membership .formWrap.twoFactor .singleFieldSubmission button[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .formWrap.twoFactor #TFAButtonApp {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
  padding: 0.35em 0.5em;
  margin-bottom: 20px;
}
@media (hover: hover) and (pointer: fine) {
  #membership .formWrap.twoFactor #TFAButtonApp:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.twoFactor #TFAButtonApp:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #membership .formWrap.twoFactor #TFAButtonApp:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp[disabled], #membership .formWrap.twoFactor #TFAButtonApp[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .formWrap.twoFactor #TFAButtonEmail,
#membership .formWrap.twoFactor #OTPButton {
  border: 0;
  background: none;
  padding: 0 0 2px;
  margin-top: 15px;
  border-bottom: 1px solid #0039a6;
  color: #0039a6;
  background-color: white;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonEmail[data-wait-a-few=true],
#membership .formWrap.twoFactor #OTPButton[data-wait-a-few=true] {
  opacity: 0.6;
  cursor: not-allowed;
}
#membership .buttonHolder {
  text-align: center;
}
#membership .buttonHolder button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
  margin: 0 auto;
}
@media (hover: hover) and (pointer: fine) {
  #membership .buttonHolder button:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .buttonHolder button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #membership .buttonHolder button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .buttonHolder button.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .buttonHolder button:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#membership .buttonHolder button[disabled], #membership .buttonHolder button[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .styledFieldset {
  margin-bottom: 20px;
}
#membership .styledFieldset .groupLegend {
  margin-bottom: 10px;
  font-family: "Arial", sans-serif;
}

.simpleDialog .closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
}
@media (hover: hover) and (pointer: fine) {
  .simpleDialog .closeDialog:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] .simpleDialog .closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie .simpleDialog .closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.simpleDialog .closeDialog.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.simpleDialog .closeDialog:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.simpleDialog .closeDialog[disabled], .simpleDialog .closeDialog[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
.simpleDialog .closeDialog .icon {
  display: none;
}

#resetData button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  padding: 10px 16px 14px 16px;
  line-height: 1;
  background-color: #0039a6;
  color: white;
}
@media (hover: hover) and (pointer: fine) {
  #resetData button:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #00aeef;
    color: black;
    border-color: #0089bc;
    cursor: pointer;
  }
}
[data-browser=ie] #resetData button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
.ie #resetData button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#resetData button.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#resetData button:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #00aeef;
  color: black;
  border-color: #0089bc;
  cursor: pointer;
}
#resetData button[disabled], #resetData button[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 1500px) {
  #mainImg {
    top: 60px;
    left: 60px;
    height: calc(100% - 120px);
  }
  #background:before {
    width: 75%;
  }
  #membership .titleArea {
    font-size: 2.25em;
  }
  #membership .formWrap.landingPage .buttons .icon {
    font-size: 5em;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 180px;
    height: 180px;
  }
  .orientationTitle {
    font-size: 1.75em;
  }
  #backgroundPlayBtn {
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    font-size: 1.25em;
  }
}
@media (max-width: 1280px) {
  #mainImg {
    top: 40px;
    left: 40px;
    height: calc(100% - 80px);
    max-width: 41%;
  }
  #background {
    width: 55%;
  }
  #background:before {
    width: 80%;
  }
  #membership {
    max-width: 48%;
  }
  #membership .formWrap.landingPage .buttons .text {
    font-size: 1.5em;
  }
  #membership .titleArea {
    font-size: 2em;
  }
  #backgroundPlayBtn {
    top: 5px;
    left: 5px;
    width: 25px;
    height: 30px;
    font-size: 1em;
  }
}
@media (max-width: 1050px) {
  #membership .titleArea {
    font-size: 1.75em;
  }
  #membership .formWrap {
    padding: 25px 0;
  }
  #membership .formWrap.landingPage .buttons .icon {
    font-size: 4em;
  }
  #membership .formWrap.landingPage .buttons .text {
    font-size: 1.225em;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 140px;
    height: 140px;
  }
  #background::before {
    width: 85%;
  }
  #mainImg {
    height: calc(100% - 100px);
    max-width: 39%;
  }
  .orientationTitle {
    font-size: 1.5em;
    right: 240px;
  }
  .branding {
    height: 110px;
  }
  .branding .logo {
    height: 90px;
  }
}
@media (max-width: 850px) {
  #background {
    width: 100%;
    min-height: 200px;
    height: auto;
  }
  #background:before {
    display: none;
  }
  #background:after {
    right: 0;
  }
  .membershipPage #backgroundPlayBtn {
    display: block;
  }
  #backgroundPlayBtn {
    left: auto;
    right: 0;
    top: 0;
    width: 35px;
    height: 35px;
    background: #0039a6;
    z-index: 999;
  }
  #backgroundPlayBtn .text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .orientationTitle {
    z-index: 10;
  }
  #mainImg {
    position: absolute;
    max-width: calc(100% - 40px);
    left: 20px;
    top: 20px;
    background-position: 50% 25%;
    cursor: pointer;
    min-height: 200px;
    height: auto;
  }
  #mainImg .icon {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px;
    background-color: #fff;
    color: black;
    font-size: 0.8em;
  }
  #mainImg.shrink {
    width: 60px;
    height: 60px;
    outline: 4px solid #fff;
    min-height: 60px;
  }
  #mainImg.shrink .icon::before {
    content: "u";
  }
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  header {
    height: auto;
  }
  header .branding {
    flex-direction: column;
  }
  header .branding .logoWrap {
    justify-content: center;
    width: 100%;
    max-width: 280px;
    flex: auto;
    margin-bottom: 10px;
  }
  header .branding .orientationTitle {
    text-align: center;
  }
  #membership {
    max-width: 100%;
    top: 200px;
    left: 0;
    padding: 60px 20px 120px 20px;
    background: #fff;
    justify-content: flex-start;
  }
  #membership .formWrap.login .loginWrapper {
    flex-direction: column;
    gap: 15px;
  }
  #membership .formWrap.login .newAccountWrapper {
    width: auto;
    margin: auto;
  }
  #membership .formWrap.login .returningWrapper {
    width: auto;
  }
  #membership .formWrap.landingPage {
    padding-bottom: 100px;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 180px;
    height: 180px;
    aspect-ratio: 1/1;
  }
  #membership .formWrap.landingPage .buttons .text {
    pointer-events: all;
  }
}
@media (max-width: 600px) {
  .orientationTitle {
    position: absolute;
    top: 0;
    right: 30px !important;
    bottom: auto !important;
    padding: 5px 10px;
    font-size: 1.1em;
    color: #fff;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .orientationTitle:before {
    transform: skew(-15.1deg);
    background: #0039a6;
    position: absolute;
    width: calc(100% + 30px);
    right: -20px;
    bottom: 0;
    height: 100%;
    content: "";
    z-index: -1;
  }
  #membership .titleArea {
    font-size: 1.5em;
  }
  #membership .formWrap {
    padding: 0;
  }
  #membership .formWrap.landingPage p {
    font-size: 1em;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 140px;
    height: 140px;
  }
  #membership .formWrap.landingPage .buttons a .icon {
    font-size: 3em;
  }
  #membership .formWrap.login .newAccountWrapper {
    width: auto;
  }
  #resetData button {
    width: 100%;
    margin-bottom: 8px;
  }
}
@media (max-width: 480px) {
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
  header .orientationTitle {
    font-size: 1.8em;
  }
  #membership {
    top: 160px;
    padding: 40px 20px 120px 20px;
  }
  #membership .titleArea {
    font-size: 1.25em;
  }
  #membership .formWrap {
    margin-top: 0;
  }
  #membership .formWrap.landingPage .buttons {
    gap: 0 60px;
  }
  #membership .formWrap.landingPage .buttons .text {
    font-size: 1.1em;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 120px;
    height: 120px;
  }
  .branding {
    height: 90px;
    width: 200px;
  }
  .branding .logo {
    height: 70px;
  }
  #mainImg,
  #background {
    min-height: 160px;
  }
}
.rte {
  clear: both;
}
.rte h4,
.rte h5 {
  margin: 0.8em 0 0.5em;
}
.rte > *:first-child {
  margin-top: 0 !important;
}
.rte > *:last-child {
  margin-bottom: 0 !important;
}

.rte {
  overflow: hidden;
  font-size: 1.125em;
}
.rte iframe {
  max-width: calc(100vw - 80px);
  max-height: calc((100vw - 80px) / 1.7778); /* 16:9 */
}
.rte img {
  margin: 10px;
}
.rte img[style*="float: right"] {
  margin: 0 0 20px 20px;
}
.rte img[style*="float: left"] {
  margin: 0 20px 20px 0px;
}
.rte img[style*="float: right"], .rte img[style*="float: left"] {
  max-width: 60%;
}
@media (max-width: 600px) {
  .rte img[style*="float: right"], .rte img[style*="float: left"] {
    max-width: 50%;
  }
}
@media (max-width: 480px) {
  .rte img[style*="float: right"], .rte img[style*="float: left"] {
    max-width: 100%;
    float: none !important;
    margin: 0 auto 8px;
  }
}
.rte figure:not(.termBbl) {
  margin: 20px 0px;
  border-radius: calc(0 + 5px - 0px);
  border: 1px solid #cccccc;
  padding: 5px;
  display: inline-block;
}
.rte figure:not(.termBbl) img {
  float: none !important;
  margin: 0px !important;
  max-width: 100% !important;
  border-radius: 0 0 0px 0px;
}
.rte figure:not(.termBbl)[style*="text-align: right"] {
  float: right;
  margin: 0 0 20px 20px;
}
.rte figure:not(.termBbl)[style*="text-align: left"] {
  float: left;
  margin: 0 20px 20px 0px;
}
.rte figure:not(.termBbl)[style*="text-align: left"], .rte figure:not(.termBbl)[style*="text-align: right"] {
  text-align: left !important;
  max-width: 60%;
}
@media (max-width: 600px) {
  .rte figure:not(.termBbl)[style*="text-align: left"], .rte figure:not(.termBbl)[style*="text-align: right"] {
    max-width: 50%;
  }
}
@media (max-width: 480px) {
  .rte figure:not(.termBbl)[style*="text-align: left"], .rte figure:not(.termBbl)[style*="text-align: right"] {
    max-width: 100%;
    float: none !important;
    margin: 0 auto 8px;
  }
}
.rte figure:not(.termBbl) figcaption {
  font-weight: 400;
  padding: 10px;
  border-radius: 0px 0px 0 0;
  background-color: #eeeeee;
  color: black;
  font-size: 0.9em;
}
.rte p {
  font-family: "Lato", sans-serif;
  font-size: 1em;
}
.rte h3.heading {
  margin: 0.4em 0 0.4em;
  font-family: "Oswald", sans-serif;
  padding: 8px 15px;
  font-size: 1.35em;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.rte h3.heading:before {
  content: "";
  background: #0039a6;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: -15px;
  width: calc(100% + 30px);
  transform: skew(-15deg);
  z-index: -1;
}
.rte .headingList,
.rte .subheadingList {
  font-family: "Lato", sans-serif;
  margin: 1em 0 0.6em;
  padding-bottom: 0.25em;
  font-weight: 700;
  font-style: italic;
  color: #767679;
  font-size: 1.125em;
  position: relative;
}
.rte .headingList:before,
.rte .subheadingList:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 30px;
  height: 3px;
  background: #cc0000;
}
.rte .subheadingList {
  border-bottom: 2px dotted #0039a6;
  padding-bottom: 10px;
  font-weight: 400;
  font-style: normal;
  color: #3f3f3f;
}
.rte .subheadingList:before {
  display: none;
}
.rte h4.subheading {
  margin: 0.75em 0 0.4em;
  font-size: 1.35em;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #333;
  border-radius: 8px;
}
.rte .largeText {
  position: relative;
  padding-top: 13px;
  margin-bottom: 25px;
  display: block;
  font-size: 1.3em;
  font-family: "Lato", sans-serif;
  color: #0071ce;
  font-weight: bold;
  font-style: italic;
  text-wrap: pretty;
}
.rte .highlight {
  background: #00aeef;
  color: #333;
  padding: 0 4px;
}
.rte ul {
  padding: 0;
}
.rte ul > li {
  margin-left: 40px;
  margin-bottom: 5px;
}
.rte ul > li::marker {
  font-size: 1.25em;
  color: #cc0000;
}
.rte ol {
  padding: 0;
  list-style-type: none;
  counter-reset: counter;
}
.rte ol[style="list-style-type: upper-alpha;"] > li:before, .rte ol[style="list-style-type: lower-alpha;"] > li:before, .rte ol[style="list-style-type: lower-greek;"] > li:before, .rte ol[style="list-style-type: upper-roman;"] > li:before, .rte ol[style="list-style-type: lower-roman;"] > li:before {
  display: none;
}
.rte ol > li {
  position: relative;
  counter-increment: counter;
  margin-left: 40px;
  margin-bottom: 5px;
}
.rte ol > li:before {
  content: counter(counter) ". ";
  position: absolute;
  right: calc(100% + 8px);
  color: #0039a6;
  font-weight: bold;
}
.rte a {
  text-decoration: none;
  color: #0039a6;
  background-color: white;
  border-bottom: 2px solid #0039a6;
}
@media (hover: hover) and (pointer: fine) {
  .rte a:hover {
    color: #3f3f3f;
    background-color: white;
    border-bottom-color: #3f3f3f;
  }
}
[data-browser=ie] .rte a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.ie .rte a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte a.focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte a:focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte a:focus-visible, .rte a.focus-visible {
  outline: none !important;
  border: 2px solid #cc0000 !important;
}
.rte .tableWrapper {
  overflow: auto;
  max-width: 100%;
  margin-bottom: 20px;
  background: linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgba(51, 51, 51, 0.2), rgba(51, 51, 51, 0)), radial-gradient(farthest-side at 100% 50%, rgba(51, 51, 51, 0.2), rgba(51, 51, 51, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  container-type: inline-size;
}
.rte .tableWrapper table {
  min-width: 500px;
  margin-bottom: 5px;
  border: none;
}
.rte .tableWrapper table caption {
  padding: 4px;
  font-weight: 500;
  background-color: #0039a6;
  color: white;
}
.rte .tableWrapper table tr:nth-child(even) {
  background: #fff;
}
.rte .tableWrapper table tr:nth-child(odd) {
  background: #eeeeee;
}
.rte .tableWrapper table th {
  padding: 3px;
  background-color: #00aeef;
  color: black;
  border-color: #eeeeee;
}
.rte .tableWrapper table td {
  padding: 10px;
  font-size: 0.95em;
  border-color: #eeeeee;
}
.rte .tableWrapper table a {
  background: none;
}
.rte .tableWrapper .mobileScrollMessage {
  position: relative;
  display: none;
  margin-top: 8px;
  padding: 5px 8px;
  border-radius: 0;
  background-color: #0039a6;
  color: white;
}
.rte .tableWrapper .mobileScrollMessage:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 8px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 6px 8px 5px;
  border-color: transparent transparent #0039a6 transparent;
}
.rte.calloutSubtle {
  margin: 0.75em 0 0.9em;
  padding: 10px 15px;
  border-radius: 0;
  background-color: #eeeeee;
  color: black;
}
.rte.calloutSubtle a {
  background-color: #eeeeee;
  color: black;
  border-bottom: 2px solid black;
}
@media (hover: hover) and (pointer: fine) {
  .rte.calloutSubtle a:hover {
    color: #3f3f3f;
    background-color: white;
    border-bottom-color: #3f3f3f;
  }
}
[data-browser=ie] .rte.calloutSubtle a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.ie .rte.calloutSubtle a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle a.focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle a:focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutSubtle > *:last-child {
  margin-bottom: 0 !important;
}
.rte.calloutBold {
  margin: 0.75em 0 0.9em;
  padding: 10px 15px;
  border-radius: 0;
  background-color: #0039a6;
  color: white;
}
.rte.calloutBold a {
  background-color: #0039a6;
  color: white;
  border-bottom: 2px solid white;
}
@media (hover: hover) and (pointer: fine) {
  .rte.calloutBold a:hover {
    color: #3f3f3f;
    background-color: white;
    border-bottom-color: #3f3f3f;
  }
}
[data-browser=ie] .rte.calloutBold a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.ie .rte.calloutBold a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold a.focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold a:focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutBold > *:last-child {
  margin-bottom: 0 !important;
}

@container (max-width: 500px) {
  .rte .tableWrapper .mobileScrollMessage {
    display: inline-block;
  }
}
@media (min-width: 2200px) {
  .rte {
    font-size: 1.425em;
  }
}
@media (max-width: 600px) {
  .rte .tableWrapper .mobileScrollMessage {
    display: inline-block;
  }
}
#membership .formWrap.maintenance {
  background: #00aeef;
  border-color: #fff;
}
#membership .formWrap.maintenance p {
  color: #333;
}
#membership .formWrap.maintenance .formArea {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: transparent;
}
#membership .formWrap.maintenance .formArea .rte {
  color: #fff;
  padding-right: 25px;
}
#membership .formWrap.maintenance .formArea .icon {
  flex-shrink: 0;
  flex-grow: 0;
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16em;
  border-left: 2px solid #89dfff;
  color: #0039a6;
}

@media (max-width: 1500px) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 250px;
    height: 250px;
    font-size: 8em;
  }
}
@media (max-width: 1280px) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 200px;
    height: 200px;
    font-size: 6em;
  }
}
@media (max-width: 1050px) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 175px;
    height: 175px;
    font-size: 4em;
  }
}
@media (max-width: 850px) {
  #membership .formWrap.maintenance .formArea .icon {
    width: 30%;
    height: 150px;
    font-size: 6em;
  }
}
@media (max-width: 600px) {
  #membership .formWrap.maintenance .formArea {
    flex-direction: column-reverse;
    justify-content: center;
  }
  #membership .formWrap.maintenance .formArea .rte {
    padding-right: 0;
  }
  #membership .formWrap.maintenance .formArea .icon {
    width: 75px;
    height: 75px;
    font-size: 3em;
    padding: 0;
    margin-bottom: 0;
    border-left: 0;
    border-bottom: 2px solid #89dfff;
  }
}
.membershipPage .errorPage .errorWrap {
  padding: 30px 20px;
  border-top: 5px solid #0039a6;
  box-shadow: 0 2px 4px #eeeeee;
}
.membershipPage .errorPage .icon {
  padding: 0 15px 15px 15px;
  font-size: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
}
.membershipPage .errorPage .rte {
  text-align: center;
  padding: 10px;
  width: 100%;
}
.membershipPage .errorPage .rte h3.heading {
  color: #0039a6;
}
.membershipPage .errorPage .rte h3.heading:before {
  display: none;
}

@media (max-width: 850px) {
  .membershipPage .errorPage .icon {
    font-size: 6em;
  }
}
@media (max-width: 600px) {
  .membershipPage .errorPage .errorWrap {
    flex-direction: column;
  }
  .membershipPage .errorPage .icon {
    font-size: 5em;
    border-left: 0;
    padding-bottom: 15px;
    border-bottom: 2px solid rgb(167, 196, 221);
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .membershipPage .errorPage .rte {
    width: 100%;
    padding: 15px 0;
  }
}
details {
  transition: all 500ms;
}

details > summary::marker,
details > summary::-webkit-details-marker {
  display: none;
}

.accordionModule .accordionTitle {
  cursor: pointer;
  list-style: none;
}

.accordionModule {
  position: relative;
  margin: 10px 0 20px;
}
.accordionModule[open] > .accordionTitle {
  background: #0071ce;
  color: #fff;
}
.accordionModule[open] > .accordionTitle .icon {
  color: #fff;
}
.accordionModule[open] > .accordionTitle .icon:before {
  content: "v";
  font-family: "georgiaState";
}
.accordionModule .accordionTitle {
  position: relative;
  padding: 8px 60px 12px 20px;
  color: #333;
  border-top: 4px solid #0071ce;
  border-left: 4px solid #0071ce;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-size: 1.35em;
  transition: 300ms all;
}
@media (hover: hover) and (pointer: fine) {
  .accordionModule .accordionTitle:hover {
    background: #0071ce;
    color: #fff;
  }
  .accordionModule .accordionTitle:hover .icon {
    color: #fff;
  }
}
[data-browser=ie] .accordionModule .accordionTitle:hover {
  background: #0071ce;
  color: #fff;
}
[data-browser=ie] .accordionModule .accordionTitle:hover .icon {
  color: #fff;
}
.ie .accordionModule .accordionTitle:hover {
  background: #0071ce;
  color: #fff;
}
.ie .accordionModule .accordionTitle:hover .icon {
  color: #fff;
}
.accordionModule .accordionTitle.focus-visible {
  background: #0071ce;
  color: #fff;
}
.accordionModule .accordionTitle.focus-visible .icon {
  color: #fff;
}
.accordionModule .accordionTitle:focus-visible {
  background: #0071ce;
  color: #fff;
}
.accordionModule .accordionTitle:focus-visible .icon {
  color: #fff;
}
.accordionModule .accordionTitle .icon {
  color: #333;
  position: absolute;
  top: 12px;
  right: 18px;
  font-size: 0.75em;
  transition: 300ms all;
}
.accordionModule .accordionTitle .icon:before {
  content: "u";
  font-family: "georgiaState";
}
.accordionModule .accordionWrapper {
  padding: 15px 20px 20px;
  border-left: 4px solid #0071ce;
  background-color: #fff;
  color: black;
}
.accordionModule .accordionWrapper img {
  max-width: 100%;
  height: auto !important;
}