@use "variables" as *;
@use "mixins" as *;
@use "sass:math";

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

details {
  // If you change this transition speed, you'll have to change it at the top of Accordion.ts
  transition: all $medium;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

.accordionModule {
  .accordionTitle {
    cursor: pointer;
    list-style: none;
  }
}

// END BASE STYLES //

.accordionModule {
  position: relative;
  margin: 10px 0 20px;

  &[open] {
    > .accordionTitle {
      background: $coolBlue;
      color: $light;

      .icon {
        &:before {
          content: "v";
          font-family: $iconFont;
        }
        color: $light;
      }
    }
  }

  .accordionTitle {
    position: relative;
    padding: 8px 60px 12px 20px;
    color: $black;
    border-top: 4px solid $coolBlue;
    border-left: 4px solid $coolBlue;
    font-family: $secondaryFont;
    //text-transform: uppercase;
    font-weight: 400;
    font-size: 1.35em;
    transition: $fast all;
    @include hoverAndFocus {
      background: $coolBlue;
      color: $light;
      .icon {
        color: $light;
      }
    }

    .icon {
      &:before {
        content: "u";
        font-family: $iconFont;
      }
      color: $black;
      position: absolute;
      top: 12px;
      right: 18px;
      font-size: 0.75em;
      transition: $fast all;
    }
  }

  .accordionWrapper {
    padding: 15px 20px 20px;
    border-left: 4px solid $coolBlue;
    @include bgWithContrast($light);
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
