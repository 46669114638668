// Colors
$primary: #0039a6; // Dark Blue
$secondary: #00aeef; // Vibrant Blue
$coolBlue: #0071ce;
$red: #cc0000; // Red
$purple: #542e8e; // Purple

$primaryHover: $primary;
$secondaryHover: $red;

$light: #fff; // White
$lightGrey: #eeeeee; // Light Grey
$grey: #cccccc; // Grey
$darkGrey: #767679; // Dark Grey
$black: #333; // Black
$basic: #3f3f3f; // Dark Grey

$link: $primary;

//Hover color can be a 3rd color is school has one
$hover: darken($primary, 10%);
//ADA hover needs to be a color that contrasts with
//the primary color but also is dark enough to see white against.
$ADAhover: $basic;

$wrong: $red; //Red
$correct: $purple; //Green

// Fonts
$primaryFont: "Oswald", sans-serif;
//$secondaryFont: "fave-script-pro", sans-serif;
$secondaryFont: "proxima-nova", sans-serif;
$basicFont: "Lato", sans-serif;
//$proxima: "proxima-nova", sans-serif;

$genericFont: "Arial", sans-serif;

// Widths
$tiny: 400px;
$xxs: 480px;
$xs: 600px;
$sm: 850px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;
$xxl: 2200px;

// Animation Speeds - these also need to be changed in animation.ts if modified
$veryFast: 200ms;
$fast: 300ms;
$medium: 500ms;
$slow: 800ms;
$verySlow: 1000ms;
$glacial: 3000ms;
