@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;
  z-index: 99;

  .adgLogoWrap {
    a {
      display: block;
      background: #000 !important;
    }

    .adgLogo {
      height: 30px;
      width: auto;
    }
  }
}

.guestLoginMethods {
  .socialMediaTitle {
    margin-bottom: 15px;
    font-weight: 400;
  }

  .socialMediaLogins {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
    .facebookLogin {
      background: #1877f2 !important;
      color: white !important;
    }
    .googleLogin {
      background: #ebebec !important;
      color: black !important;
    }
    .twitterLogin {
      background: #14171a !important;
      color: white !important;
      .text {
        @include screenReader;
      }
    }
    .appleLogin {
      background: black !important;
      color: white !important;
    }
    > a {
      @include flexCenter;
      text-decoration: none !important;
      border-radius: $radius;
      height: 45px;
      text-align: center;
      padding: 1em !important;

      .svgIcon {
        width: 16px;
        height: auto;
        margin-right: 7px;
      }
      .text {
        font-weight: bold;
        font-size: 1.1em;
      }
    }
    .appleLogin {
      height: 45px;
      cursor: pointer;
      > div {
        max-width: 100% !important;
      }
    }
  }
}

.loginWrapper[data-hidden-for-guest="true"] {
  display: none !important;
}

.guestWrapperHolder {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all $slow;
  text-align: left;
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.enrolleeName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES //

body {
  overflow-x: hidden;
}

@keyframes borderRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#backgroundPlayBtn {
  //display: none;
  right: auto;
  bottom: auto;
  top: 20px;
  left: 20px;
  .text {
    font-size: 0.5em;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    transform: none;
  }
}

#background {
  width: 50%;
  &:before {
    width: 70%;
    position: fixed;
  }
  &:after {
    width: 120%;
    right: 100px;
  }
}

#mainImg {
  position: fixed;
  z-index: 10;
  top: 80px;
  left: 80px;
  height: calc(100% - 160px);
  width: 100%;
  max-width: 45%;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-size: cover;
  cursor: move;
  outline: 0px solid $light;
  transition:
    width $fast,
    height $fast,
    outline $fast,
    min-height $fast;
  .icon {
    display: none;
  }
}

.orientationTitle {
  position: fixed;
  z-index: 2;
  right: 260px;
  bottom: 20px;
  text-align: right;
  &.guest {
    bottom: 50px;
    right: 250px;
  }
}

.branding {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 140px;
  @include flexCenter;
  padding-right: 40px;
  // background-size: 60%;
  // background-position: 0% 40%;
  background-size: cover;
  transition: $medium all;
  z-index: 100;
  width: 250px;
  right: -40px;
  &:before {
    transform: skew(-15.1deg);
    background: $primary;
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    height: 100%;
    content: "";
  }
  .logo {
    height: 110px;
    z-index: 10;
    position: relative;
    bottom: 0;
    right: 0;
  }
}

#membership {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 200px);
  max-width: 41%;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 160px;
  z-index: 5;
  width: 100%;
  right: 5%;
  top: 8%;

  .titleArea {
    margin: 0;
    font-size: 2.5em;
    font-family: $primaryFont;
    text-transform: uppercase;
    color: $primary;
    padding-bottom: 25px;
    margin-bottom: 10px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 7px;
      width: calc(100% + 150px);
      background: url(/images/dot-for-slide-nav-blue.svg) repeat-x;
    }

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  button.returnToSocial {
    display: none;
    position: relative;
    padding: 5px;
    margin: 25px 0 0;
    border: 0;
    background: none;
    color: $primary;
    cursor: pointer;
    transition: all $veryFast;
    .text {
      font-size: 0.9em;
      text-decoration: underline;
    }
    .icon {
      position: relative;
      font-size: 0.8em;
      transition: all $medium;
    }
    @include hoverAndFocus {
      .text {
        color: $secondary;
      }
      .icon {
        margin-right: 3px;
      }
    }
  }

  .errorMessage,
  .field-validation-error {
    display: inline-block;
    text-align: center;
    @include bgWithContrast($wrong);
    max-width: 100%;
    font-weight: 600;
    padding: 5px 10px;
    margin: 2px auto 10px;
    border-radius: $radius;
  }

  .loginNav {
    @include flexCenter;
    margin-top: 15px;
    padding-top: 10px;
    text-align: center;
    font-size: 0.9em;

    > * {
      margin: 0 10px;
    }
  }

  .fieldWrap {
    padding: 30px 20px;
    border-top: 5px solid $primary;
    box-shadow: 0 2px 4px $lightGrey;
  }

  .formWrap {
    margin-top: 10px;
    padding: 25px 40px;
    text-align: center;
    @include bgWithContrast($light);

    p {
      font-size: 1.2em;
    }

    input[type="radio"] {
      @include radio;
    }

    input[type="checkbox"] {
      @include checkbox;
    }

    input[type="checkbox"] ~ label,
    input[type="radio"] ~ label {
      padding-left: 0.35em;
    }
  }

  .formWrap.login {
    .statusMessage {
      @include bgWithContrast($black);
      padding: 5px 10px;
      width: fit-content;
      margin: 10px auto;
    }
    .horzDivider {
      display: flex;
      align-items: center;
      margin: 35px 0;

      .line {
        height: 2px;
        flex-grow: 1;
        border-top: 3px dotted $grey;
      }

      .or {
        text-transform: uppercase;
        color: $basic;
        margin-inline: 15px;
      }
    }

    button.continueWithEmail {
      @include button;
      width: 100%;
      flex-direction: column;
      padding: 1.3em 1.8em;
      transform: none !important;
      .icon {
        font-size: 2.5em;
        margin-bottom: 10px;
      }
      .text {
        font-size: 1.2em;
        font-weight: bold;
      }
    }

    .loginWrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 8px;
    }

    .returningWrapper {
      width: calc(60% - 20px);
      text-align: center;
    }

    .newAccountWrapper {
      width: 40%;

      > a:first-child {
        @include button;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &[data-type="Student"] {
          .icon:before {
            text-transform: lowercase;
            content: "z";
          }
        }

        .icon {
          font-size: 2.5em;
          margin-bottom: 10px;
        }

        .text {
          font-weight: bold;
          font-size: 1.15em;
        }
      }
    }
  }

  .formWrap.landingPage {
    padding: 0;
    p {
      margin-bottom: 30px;
      font-size: 1.2em;
    }
    .buttons {
      @include flexCenter;
      gap: 0 100px;

      a {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background: $darkGrey;
        cursor: pointer;
        transition: $fast all;
        text-decoration: none;
        @include flexCenter;
        flex-direction: column;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          @include fillSpace;
          @include absolute;
          opacity: 0;
          border: 3px dotted $red;
          border-radius: 50%;
          z-index: -1;
          transition: $fast all;
        }
        &:after {
          content: "";
          width: 14px;
          height: 71px;
          position: absolute;
          left: -10px;
          bottom: -70px;
          background: url(/images/curved-arrow.png) no-repeat;
          background-position: center;
          transform: rotate(10deg);
          transform-origin: bottom;
          clip-path: inset(95% 20% 0% 10%);
          transition: $medium all;
        }

        @include hoverAndFocus {
          transform: scale(1.1);
          background: $primary;
          &:before {
            top: -10px;
            left: -10px;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            opacity: 1;
            animation: borderRotate 2s linear infinite;
          }
          &:after {
            clip-path: inset(5% 20% 15% 10%);
          }
          .icon {
            transform: scale(1.125) rotate(-5deg);
          }
          .text {
            top: calc(100% + 35px);
          }
        }
      }

      .text {
        position: absolute;
        top: calc(100% + 20px);
        left: 0;
        color: $primary;
        width: 100%;
        text-align: center;
        font-size: 1.7em;
        font-weight: bold;
        transition: $fast all;
        pointer-events: none;
      }

      .icon {
        @include flexCenter;
        height: 40px;
        width: 40px;
        font-family: $iconFont;
        font-size: 6em;
        transition: $medium all;
        color: $light;
      }

      .guestLoginBtn {
        //transform-origin: 25% 50%;
        .icon:before {
          content: "k";
        }
      }

      .enrolleeLoginBtn {
        //transform-origin: 75% 50%;
        .icon:before {
          content: "W";
        }
      }
    }
  }

  .formWrap.accountProfiler {
    text-align: left;

    .profileSettingsExplainer,
    .profileErrorWrap {
      text-align: center;
    }

    .instruction {
      margin-bottom: 15px;
      text-align: center;
    }

    .buttonHolder {
      margin: 20px 0 20px;
    }

    .resetAccountTypeBtn {
      border: 0;
      padding: 0;
      background: none;
      border-radius: 0;
      overflow: visible;
      font-size: 0.8em;
      border-bottom: 1px solid $primary;
      cursor: pointer;
      @include colorWithContrast($primary);
      transition: $veryFast;

      @include hoverAndFocus {
        border-color: $secondary;
        @include colorWithContrast($secondary);
      }
    }
  }

  .formWrap.twoFactor {
    .singleFieldSubmission {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      .fieldWrapper {
        margin: 0 5px 0 0;
      }

      button {
        @include button;
      }
    }

    #TFAButtonApp {
      @include button;
      padding: 0.35em 0.5em;
      margin-bottom: 20px;
    }

    #TFAButtonEmail,
    #OTPButton {
      border: 0;
      background: none;
      padding: 0 0 2px;
      margin-top: 15px;
      border-bottom: 1px solid $primary;
      @include colorWithContrast($primary);
      cursor: pointer;

      &[data-wait-a-few="true"] {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  .buttonHolder {
    text-align: center;

    button {
      @include button;
      margin: 0 auto;
    }
  }

  .styledFieldset {
    margin-bottom: 20px;

    .groupLegend {
      margin-bottom: 10px;
      font-family: $genericFont;
    }
  }
}

.simpleDialog {
  .closeDialog {
    @include button;

    .icon {
      display: none;
    }
  }
}

#resetData {
  button {
    @include button;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
@media (max-width: $xl) {
  #mainImg {
    top: 60px;
    left: 60px;
    height: calc(100% - 120px);
  }
  #background {
    &:before {
      width: 75%;
    }
  }
  #membership {
    .titleArea {
      font-size: 2.25em;
    }
    .formWrap.landingPage .buttons {
      .icon {
        font-size: 5em;
      }
      a {
        width: 180px;
        height: 180px;
      }
    }
  }
  .orientationTitle {
    font-size: 1.75em;
  }
  #backgroundPlayBtn {
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    font-size: 1.25em;
  }
}

@media (max-width: $lg) {
  #mainImg {
    top: 40px;
    left: 40px;
    height: calc(100% - 80px);
    max-width: 41%;
  }
  #background {
    width: 55%;
    &:before {
      width: 80%;
    }
  }
  #membership {
    max-width: 48%;
    .formWrap.landingPage .buttons .text {
      font-size: 1.5em;
    }
    .titleArea {
      font-size: 2em;
    }
  }
  #backgroundPlayBtn {
    top: 5px;
    left: 5px;
    width: 25px;
    height: 30px;
    font-size: 1em;
  }
}

@media (max-width: $md) {
  #membership {
    .titleArea {
      font-size: 1.75em;
    }
    .formWrap {
      padding: 25px 0;
      &.landingPage .buttons {
        .icon {
          font-size: 4em;
        }
        .text {
          font-size: 1.225em;
        }
        a {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
  #background::before {
    width: 85%;
  }
  #mainImg {
    height: calc(100% - 100px);
    max-width: 39%;
  }
  .orientationTitle {
    font-size: 1.5em;
    right: 240px;
  }
  .branding {
    height: 110px;
    .logo {
      height: 90px;
    }
  }
}

@media (max-width: $sm) {
  #background {
    width: 100%;
    min-height: 200px;
    height: auto;
    &:before {
      display: none;
    }
    &:after {
      right: 0;
    }
  }
  .membershipPage #backgroundPlayBtn {
    display: block;
  }
  #backgroundPlayBtn {
    left: auto;
    right: 0;
    top: 0;
    width: 35px;
    height: 35px;
    background: $primary;
    z-index: 999;
    .text {
      @include screenReader;
    }
  }
  .orientationTitle {
    z-index: 10;
  }
  #mainImg {
    position: absolute;
    max-width: calc(100% - 40px);
    left: 20px;
    top: 20px;
    background-position: 50% 25%;
    cursor: pointer;
    min-height: 200px;
    height: auto;
    .icon {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px;
      @include bgWithContrast($light);
      font-size: 0.8em;
    }
    &.shrink {
      width: 60px;
      height: 60px;
      outline: 4px solid $light;
      min-height: 60px;
      .icon::before {
        content: "u";
      }
    }
  }
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  header {
    height: auto;
    .branding {
      flex-direction: column;
      .logoWrap {
        justify-content: center;
        width: 100%;
        max-width: 280px;
        flex: auto;
        margin-bottom: 10px;
      }
      .orientationTitle {
        text-align: center;
      }
    }
  }
  #membership {
    max-width: 100%;
    top: 200px;
    left: 0;
    padding: 60px 20px 120px 20px;
    background: $light;
    justify-content: flex-start;
    .formWrap {
      &.login {
        .loginWrapper {
          flex-direction: column;
          gap: 15px;
        }
        .newAccountWrapper {
          width: auto;
          margin: auto;
        }
        .returningWrapper {
          width: auto;
        }
      }
      &.landingPage {
        padding-bottom: 100px;
        .buttons {
          a {
            width: 180px;
            height: 180px;
            aspect-ratio: 1/1;
          }
          .text {
            pointer-events: all;
          }
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .orientationTitle {
    position: absolute;
    top: 0;
    right: 30px !important;
    bottom: auto !important;
    padding: 5px 10px;
    font-size: 1.1em;
    color: $light;
    height: 35px;
    @include flexCenter;
    &:before {
      transform: skew(-15.1deg);
      background: $primary;
      position: absolute;
      width: calc(100% + 30px);
      right: -20px;
      bottom: 0;
      height: 100%;
      content: "";
      z-index: -1;
    }
  }
  #membership {
    .titleArea {
      font-size: 1.5em;
    }
    .formWrap {
      padding: 0;
      &.landingPage {
        p {
          font-size: 1em;
        }
        .buttons a {
          width: 140px;
          height: 140px;
          .icon {
            font-size: 3em;
          }
        }
      }
    }
    .formWrap.login .newAccountWrapper {
      width: auto;
    }
  }
  #resetData {
    button {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: $xxs) {
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
  header {
    .orientationTitle {
      font-size: 1.8em;
    }
  }
  #membership {
    top: 160px;
    padding: 40px 20px 120px 20px;
    .titleArea {
      font-size: 1.25em;
    }
    .formWrap {
      margin-top: 0;
      &.landingPage .buttons {
        gap: 0 60px;
        .text {
          font-size: 1.1em;
        }
        a {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  .branding {
    height: 90px;
    width: 200px;
    .logo {
      height: 70px;
    }
  }
  #mainImg,
  #background {
    min-height: 160px;
  }
}
