@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.rte {
  clear: both;
  h4,
  h5 {
    margin: 0.8em 0 0.5em;
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

// END BASE STYLES //

.rte {
  // BEGIN INLINE MEDIA STYLES //
  overflow: hidden;
  font-size: 1.125em;
  iframe {
    max-width: calc(100vw - (40px * 2));
    max-height: calc((100vw - (40px * 2)) / 1.7778); /* 16:9 */
  }

  img {
    margin: 10px;
    &[style*="float: right"] {
      margin: 0 0 20px 20px;
    }
    &[style*="float: left"] {
      margin: 0 20px 20px 0px;
    }
    &[style*="float: right"],
    &[style*="float: left"] {
      max-width: 60%;
      @media (max-width: $xs) {
        max-width: 50%;
      }
      @media (max-width: $xxs) {
        max-width: 100%;
        float: none !important;
        margin: 0 auto 8px;
      }
    }
  }

  $pad: 5px;
  figure:not(.termBbl) {
    margin: 20px 0px;
    border-radius: calc((#{$radius} + #{$pad}) - 0px);
    border: 1px solid $grey;
    padding: $pad;
    display: inline-block;
    img {
      float: none !important;
      margin: 0px !important;
      max-width: 100% !important;
      border-radius: $radius $radius 0px 0px;
    }
    &[style*="text-align: right"] {
      float: right;
      margin: 0 0 20px 20px;
    }
    &[style*="text-align: left"] {
      float: left;
      margin: 0 20px 20px 0px;
    }
    &[style*="text-align: left"],
    &[style*="text-align: right"] {
      text-align: left !important;
      max-width: 60%;
      @media (max-width: $xs) {
        max-width: 50%;
      }
      @media (max-width: $xxs) {
        max-width: 100%;
        float: none !important;
        margin: 0 auto 8px;
      }
    }

    figcaption {
      font-weight: 400;
      padding: 10px;
      border-radius: 0px 0px $radius $radius;
      @include bgWithContrast($lightGrey);
      font-size: 0.9em;
    }
  }

  // END INLINE MEDIA STYLES //

  // BEGIN TEXT STYLES//

  p {
    font-family: $basicFont;
    font-size: 1em;
  }

  h3.heading {
    margin: 0.4em 0 0.4em;
    font-family: $primaryFont;
    padding: 8px 15px;
    font-size: 1.35em;
    font-weight: 400;
    text-transform: uppercase;
    color: $light;
    display: inline-block;
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      background: $primary;
      @include fillSpace;
      @include absolute;
      left: -15px;
      width: calc(100% + 30px);
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  .headingList,
  .subheadingList {
    font-family: $basicFont;
    margin: 1em 0 0.6em;
    padding-bottom: 0.25em;
    font-weight: 700;
    font-style: italic;
    color: $darkGrey;
    font-size: 1.125em;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 30px;
      height: 3px;
      background: $red;
    }
  }

  .subheadingList {
    border-bottom: 2px dotted $primary;
    padding-bottom: 10px;
    font-weight: 400;
    font-style: normal;
    color: $basic;
    &:before {
      display: none;
    }
  }

  h4.subheading {
    margin: 0.75em 0 0.4em;
    font-size: 1.35em;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $black;
    border-radius: 8px;
  }

  .largeText {
    position: relative;
    padding-top: 13px;
    margin-bottom: 25px;
    display: block;
    font-size: 1.3em;
    //line-height: 1.5em;
    font-family: $basicFont;
    color: $coolBlue;
    font-weight: bold;
    font-style: italic;
    text-wrap: pretty;
  }

  .highlight {
    background: $secondary;
    color: $black;
    padding: 0 4px;
  }

  ul {
    padding: 0;

    > li {
      margin-left: 40px;
      margin-bottom: 5px;

      &::marker {
        font-size: 1.25em;
        color: $red;
      }
    }
  }

  ol {
    padding: 0;
    list-style-type: none;
    counter-reset: counter;

    &[style="list-style-type: upper-alpha;"],
    &[style="list-style-type: lower-alpha;"],
    &[style="list-style-type: lower-greek;"],
    &[style="list-style-type: upper-roman;"],
    &[style="list-style-type: lower-roman;"] {
      > li:before {
        display: none;
      }
    }

    > li {
      position: relative;
      counter-increment: counter;
      margin-left: 40px;
      margin-bottom: 5px;

      &:before {
        content: counter(counter) ". ";
        position: absolute;
        right: calc(100% + 8px);
        color: $primary;
        font-weight: bold;
      }
    }
  }

  a {
    text-decoration: none;
    @include colorWithContrast($primary);
    border-bottom: 2px solid $primary;

    @include hoverAndFocus {
      @include colorWithContrast($basic);
      border-bottom-color: $basic;
    }
    &:focus-visible,
    &.focus-visible {
      outline: none !important;
      border: 2px solid $red !important;
    }
  }

  // https://www.w3.org/WAI/WCAG21/Understanding/reflow.html
  // Examples of content which requires two-dimensional layout are [...] data tables (not individual cells), and interfaces
  // where it is necessary to keep toolbars in view while manipulating content. It is acceptable to provide two-dimensional
  // scrolling for such parts of the content.
  .tableWrapper {
    overflow: auto;
    max-width: 100%;
    margin-bottom: 20px;
    background:
      linear-gradient(to right, $light 30%, rgba($light, 0)),
      linear-gradient(to right, rgba($light, 0), $light 70%) 0 100%,
      radial-gradient(farthest-side at 0% 50%, rgba($black, 0.2), rgba($black, 0)),
      radial-gradient(farthest-side at 100% 50%, rgba($black, 0.2), rgba($black, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: $light;
    background-size:
      40px 100%,
      40px 100%,
      14px 100%,
      14px 100%;
    background-position:
      0 0,
      100%,
      0 0,
      100%;
    background-attachment: local, local, scroll, scroll;
    container-type: inline-size;

    table {
      min-width: 500px;
      margin-bottom: 5px;
      border: none;
      caption {
        padding: 4px;
        font-weight: 500;
        @include bgWithContrast($primary);
      }
      tr:nth-child(even) {
        background: $light;
      }
      tr:nth-child(odd) {
        background: $lightGrey;
      }
      th {
        padding: 3px;
        @include bgWithContrast($secondary);
        border-color: $lightGrey;
      }
      td {
        padding: 10px;
        font-size: 0.95em;
        border-color: $lightGrey;
      }
      a {
        background: none;
      }
    }

    .mobileScrollMessage {
      position: relative;
      display: none;
      margin-top: 8px;
      padding: 5px 8px;
      border-radius: $radius;
      @include bgWithContrast($primary);
      &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 8px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 6px 8px 5px;
        border-color: transparent transparent $primary transparent;
      }
    }
  }

  &.calloutSubtle {
    margin: 0.75em 0 0.9em;
    padding: 10px 15px;
    border-radius: $radius;
    @include bgWithContrast($lightGrey);

    a {
      @include bgWithContrast($lightGrey);
      border-bottom: 2px solid contrast($lightGrey);

      @include hoverAndFocus {
        @include colorWithContrast($basic);
        border-bottom-color: $basic;
      }
    }

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.calloutBold {
    margin: 0.75em 0 0.9em;
    padding: 10px 15px;
    border-radius: $radius;
    @include bgWithContrast($primary);

    a {
      @include bgWithContrast($primary);
      border-bottom: 2px solid contrast($primary);

      @include hoverAndFocus {
        @include colorWithContrast($basic);
        border-bottom-color: $basic;
      }
    }

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
@container (max-width: 500px) {
  .rte .tableWrapper .mobileScrollMessage {
    display: inline-block;
  }
}

@media (min-width: $xxl) {
  .rte {
    font-size: 1.425em;
  }
}

@media (max-width: $xs) {
  .rte .tableWrapper {
    .mobileScrollMessage {
      display: inline-block;
    }
  }
}
