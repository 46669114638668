@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

willis-zone {
  display: block;
}

[inert] {
  overflow: hidden;
  pointer-events: none;
}

willis-dialog:not([data-open="true"]) {
  display: none !important;
}

.dialogBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .slide & {
    height: 100%; //Applied this because of issues responsivley with dialogs within the slides at smaller screen sizes. If it messes things up, we can remove. - KS
  }
}

.dialogInner {
  position: relative;
}

[data-dialog-style="modal"] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  @include scrollbar;

  div[role="dialog"] {
    width: 680px;
    margin: auto;

    &.reportNeeded {
      //Unique styling for the Report a Barrier feature. - DFT
      width: 80%;
      max-width: 1000px;
    }
  }
}

[data-dialog-style="largeModal"] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  @include scrollbar;

  div[role="dialog"] {
    width: 800px;
    max-width: 100vw;
    margin: auto;
  }
}

[data-dialog-style="shade"] {
  overflow-y: scroll;

  div[role="dialog"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    @include bgWithContrast($light);

    .dialogLabel {
      max-width: 800px;
    }

    .dialogContent {
      max-width: 800px;
    }

    .dialogActions {
      max-width: 800px;
    }
  }
}

[data-dialog-style^="sidebar"] {
  display: flex;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;

  &[data-dialog-style="sidebarLeft"] {
    justify-content: flex-start;
  }

  &[data-dialog-style="sidebarRight"] {
    justify-content: flex-end;
  }

  div[role="dialog"] {
    width: 30%;
  }
}

[data-dialog-style^="full"] {
  display: flex;
  align-items: stretch;
  overflow: hidden;

  div[role="dialog"] {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 600px) {
  [data-dialog-style^="sidebar"] {
    div[role="dialog"] {
      width: 100%;
      min-width: 0;
    }
  }

  [data-dialog-style="modal"] {
    div[role="dialog"] {
      width: 100%;
    }
  }
}

// Animation
div[data-dialog-animation-speed="glacial"] {
  animation-duration: $glacial;
}

div[data-dialog-animation-speed="verySlow"] {
  animation-duration: $verySlow;
}

div[data-dialog-animation-speed="slow"] {
  animation-duration: $slow;
}

div[data-dialog-animation-speed="medium"] {
  animation-duration: $medium;
}

div[data-dialog-animation-speed="fast"] {
  animation-duration: $fast;
}

div[data-dialog-animation-speed="veryFast"] {
  animation-duration: $veryFast;
}

.dialogBackdrop {
  animation-fill-mode: both;
  animation-duration: $veryFast;

  &.in {
    animation-name: fadeIn;
  }

  &.out {
    animation-name: fadeOut;
  }
}

div[role="dialog"] {
  position: relative;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

div[data-dialog-animation-name="fadeIn"] {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

div[data-dialog-animation-name="fadeOut"] {
  animation-name: fadeOut;
}

@keyframes fadeTopIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

div[data-dialog-animation-name="fadeTopIn"] {
  animation-name: fadeTopIn;
}

@keyframes fadeTopOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}

div[data-dialog-animation-name="fadeTopOut"] {
  animation-name: fadeTopOut;
}

@keyframes fadeBottomIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

div[data-dialog-animation-name="fadeBottomIn"] {
  animation-name: fadeBottomIn;
}

@keyframes fadeBottomOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(50%);
  }
}

div[data-dialog-animation-name="fadeBottomOut"] {
  animation-name: fadeBottomOut;
}

@keyframes fadeRightIn {
  from {
    opacity: 0;
    transform: translateX(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

div[data-dialog-animation-name="fadeRightIn"] {
  animation-name: fadeRightIn;
}

@keyframes fadeRightOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }

  to {
    opacity: 0;
    transform: translateX(50%);
  }
}

div[data-dialog-animation-name="fadeRightOut"] {
  animation-name: fadeRightOut;
}

@keyframes fadeLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

div[data-dialog-animation-name="fadeLeftIn"] {
  animation-name: fadeLeftIn;
}

@keyframes fadeLeftOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }

  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}

div[data-dialog-animation-name="fadeLeftOut"] {
  animation-name: fadeLeftOut;
}

@keyframes slideTopIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

div[data-dialog-animation-name="slideTopIn"] {
  animation-name: slideTopIn;
  @media (max-width: $sm) {
    animation-name: slideBottomIn;
  }
}

@keyframes slideTopOut {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

div[data-dialog-animation-name="slideTopOut"] {
  animation-name: slideTopOut;
}

@keyframes slideBottomIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

div[data-dialog-animation-name="slideBottomIn"] {
  animation-name: slideBottomIn;
}

@keyframes slideBottomOut {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(150%);
  }
}

div[data-dialog-animation-name="slideBottomOut"] {
  animation-name: slideBottomOut;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

div[data-dialog-animation-name="slideRightIn"] {
  animation-name: slideRightIn;
}

@keyframes slideRightOut {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

div[data-dialog-animation-name="slideRightOut"] {
  animation-name: slideRightOut;
}

@keyframes slideLeftIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

div[data-dialog-animation-name="slideLeftIn"] {
  animation-name: slideLeftIn;
}

@keyframes slideLeftOut {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

div[data-dialog-animation-name="slideLeftOut"] {
  animation-name: slideLeftOut;
}

// END BASE STYLES

.dialogBackdrop {
  background: rgba($primary, 0.95);
}

.dialogContent {
  margin: 10px 0;
}

.dialogActions.generated {
  button.closeDialog {
    .icon:before {
      font-family: $iconFont;
      content: "b";
    }
  }
}

button.closeDialog {
  .icon:before {
    font-family: $iconFont;
    content: "b";
  }
}

.dialogLabel h2 {
  margin-bottom: 15px;
}

[data-dialog-style="modal"] {
  padding: 20px;

  .safari & {
    padding: 20px 20px 150px;
  }

  .dialogInner {
    border-radius: $radius;
    padding: 20px;
    text-align: center;
    @include bgWithContrast($light);
    box-shadow: $shadow;
  }

  button.closeDialog {
    @include button;

    .icon {
      display: none;
    }
  }
}

[data-dialog-style="largeModal"] {
  .safari & {
    padding: 20px 20px 150px;
  }
  .dialogInner {
    border-radius: $radius;
    padding: 20px;
    @include bgWithContrast($light);
  }

  .dialogLabel {
    text-align: center;
    margin-bottom: 25px;
    border-bottom: 1px solid $primary;
    padding: 5px 10px 20px;
    text-transform: uppercase;

    h2 {
      @include zeroMargPad;
      font-size: 1.25em;
      color: $primary;
    }
  }
}

[data-dialog-style="shade"] {
  .dialogInner {
    border-radius: 0 0 $radius $radius;
    padding: 20px;
  }
}

[data-dialog-style^="sidebar"] {
  .dialogInner {
    min-height: 100%;
  }

  &[data-dialog-style="sidebarLeft"] {
    .dialogInner {
      border-radius: 0 $radius $radius 0;
      padding: 20px;
    }
  }

  &[data-dialog-style="sidebarRight"] {
    .dialogInner {
      border-radius: $radius 0 0 $radius;
      padding: 20px;
    }
  }
}

[data-dialog-style^="full"] {
  .dialogInner {
    padding: 20px;
  }
}

.simpleDialog {
  @include bgWithContrast($light);
  border-radius: $radius;
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $sm) {
  [data-dialog-style="modal"] div[role="dialog"].reportNeeded {
    width: 100%;
  }
  [data-dialog-style^="sidebar"][data-dialog-style="sidebarRight"] .dialogInner {
    border-radius: 0;
  }
  [data-dialog-style="modal"] div[role="dialog"] {
    width: 100%;
  }
  [data-dialog-style="largeModal"] .dialogInner {
    margin: 0 20px;
  }
}

@media (max-width: $xs) {
  [data-dialog-style="modal"] {
    div[role="dialog"] {
      &.reportNeeded {
        //Unique styling for the Report a Barrier feature. - DFT
        width: 100%;
      }
    }
  }
}

@media (max-width: $xxs) {
  [data-dialog-style="modal"] {
    &[data-backdrop-for="accessibilityPrefs"] {
      .safari & {
        padding: 20px 20px 150px;
      }
    }
  }
}
