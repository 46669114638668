@use "mixins" as *;
@use "variables" as *;

//KEYFRAME ANIMATIONS FOR ZOOM SLIDESHOW//

@keyframes zoomIn {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.3);
  }
}
@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: $medium opacity;
  .menuOut & {
    &:after {
      transform: skew(15deg);
      right: -250px;
      width: 150%;
    }
  }
  [data-is-loading="true"] & {
    opacity: 0;
  }
  &:after {
    content: "";
    @include absolute;
    left: auto;
    right: 320px;
    @include fillSpace;
    background: $primary;
    mix-blend-mode: multiply;
    z-index: 2;
    transform: skew(-15deg);
    transition: $verySlow all;
    [data-is-loading="true"] & {
      right: -200px;
      width: 150%;
    }
    .minimized & {
      width: 100px;
    }
    .toolsMode & {
      width: 100%;
    }
    .menuOut & {
      width: 150%;
      transform: skew(-15deg);
    }
  }
  &:before {
    content: "";
    @include fillSpace;
    position: absolute;
    right: -200px;
    width: 0;
    transform: skew(-15deg);
    background: $light;
    z-index: 3;
    transition: $slow all;
    .menuOut & {
      width: 70%;
    }
    .menuOut.centeredSlide & {
      width: 100%;
    }
  }
  .cover-slider,
  video,
  .mainBgImg {
    z-index: 2;
    @include absolute;
    @include fillSpace;
  }
  .sectionBgHolder {
    z-index: 1;
    @include absolute;
    @include fillSpace;
    .menuOut & {
      z-index: 3;
    }
    &[data-section-bg-only="true"] {
      opacity: 0;
      transition: opacity 0.5s;
      .inSection & {
        opacity: 1;
      }
    }
  }

  .bgImg,
  .sectionBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    @include bgPos();
  }

  [data-slideshow="zoomSlideshow"] {
    &.returnPause {
      .bgImgWrapper:first-child {
        opacity: 1;
      }
    }
    .bgImgWrapper {
      opacity: 0;
      animation-duration: 0.85s;
      animation-timing-function: linear;

      &.current {
        animation-fill-mode: forwards;
        animation-name: fadeIn;
        z-index: 1;
      }
      &.prev {
        animation-name: fadeOut;
        animation-fill-mode: forwards;
      }
      &:nth-child(odd) {
        .bgImg.trans {
          animation-name: zoomIn;
        }
      }
      &:nth-child(even) {
        .bgImg.trans {
          animation-name: zoomOut;
        }
      }
    }
    .bgImg {
      transform: scale(1.2);
      z-index: 1;
      animation-timing-function: linear;
      animation-duration: 10s;
      animation-fill-mode: forwards;

      //background postion (X,Y)
      &[data-bg-pos="center"] {
        background-position: 50% 50%;
      }
      &[data-bg-pos="center left"] {
        background-position: 10% 50%;
      }
      &[data-bg-pos="center right"] {
        background-position: 90% 50%;
      }
      &[data-bg-pos="top left"] {
        background-position: 10% 10%;
      }
      &[data-bg-pos="top middle"] {
        background-position: 50% 10%;
      }
      &[data-bg-pos="top right"] {
        background-position: 90% 10%;
      }
      &[data-bg-pos="bottom left"] {
        background-position: 10% 90%;
      }
      &[data-bg-pos="bottom middle"] {
        background-position: 50% 100%;
      }
      &[data-bg-pos="bottom left"] {
        background-position: 90% 90%;
      }

      //establishing the origin of the zoom animation which
      &[data-animation="center"] {
        transform-origin: center center;
      }
      &[data-animation="top right"] {
        transform-origin: top right;
      }
      &[data-animation="top left"] {
        transform-origin: top left;
      }
      &[data-animation="bottom right"] {
        transform-origin: bottom right;
      }
      &[data-animation="bottom left"] {
        transform-origin: bottom left;
      }
    }

    &.paused {
      .bgImgWrapper.trans {
        animation-play-state: paused;
      }
      .bgImg.trans {
        animation-play-state: paused;
      }
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $lightGrey;
    transition: $slow all;
  }

  [data-is-loading="true"] & {
    //opacity: 0;
  }
}

#backgroundPlayBtn,
#backgroundPlayBtnMobile {
  position: fixed;
  bottom: 30px;
  right: calc(305px + 16.5vh);
  width: 32px;
  height: 32px;
  color: $light;
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 1.5em;
  transition: $fast all;
  @include hover {
    .text {
      opacity: 1;
      visibility: visible;
    }
  }
  @include hoverAndFocus {
    transform: scale(1.1);
  }
  &:focus-visible {
    .text {
      @include screenReader;
    }
  }
  .text {
    font-size: 0.55em;
    position: absolute;
    top: calc(100% - 3px);
    right: 29px;
    transform: translate(50%, 0);
    visibility: hidden;
    opacity: 0;
    transition: $fast all;
    text-transform: uppercase;
  }
  .toolsMode & {
    opacity: 0;
    pointer-events: none;
  }
  cursor: pointer;
  transition: all $veryFast;

  @include hoverAndFocus {
  }

  @include discoveryToolsActive {
    display: none;
  }

  @include activeDialog("utilityMenu") {
    display: none;
  }

  @include activeZone("slides") {
    display: none;
  }

  [data-is-loading="true"] & {
    opacity: 0 !important;
  }

  .icon-play,
  .text.play {
    display: none;
  }
  &.pause {
    .icon-play,
    .text.play {
      display: block;
    }
    .icon-pause,
    .text.pause {
      display: none;
    }
  }
}

#backgroundPlayBtnMobile {
  display: none;
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
@media (max-width: $xl) {
  .minimized #background::after {
    width: 90px;
  }
}
@media (max-width: $lg) {
  #background::after {
    right: 248px;
  }
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    font-size: 1.25em;
    right: calc(235px + 16.5vh);
  }
  .menuOut #background:before {
    width: 80%;
  }
  .menuOut.centeredSlide #background::before {
    width: 150%;
  }
}
@media (max-width: $md) {
  .minimized #background::after {
    width: 80px;
  }
  .menuOut #background::before {
    width: calc(100% + 320px);
    right: -180px;
  }
}
@media (min-width: ($sm + 1)) and (max-width: ($sm + 50)) and (max-height: $xxs) {
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    bottom: 100px;
  }
}

@media (max-width: $sm) {
  #backgroundPlayBtnMobile {
    display: block;
  }
  #backgroundPlayBtn {
    display: none;
  }
  .minimized #background::after {
    width: 0;
  }

  .menuOut.centeredSlide #background::before {
    width: calc(100% + 320px);
  }

  #background {
    width: 100%;
    height: 100%;
    &:after {
      transform: none;
      right: 0;
    }

    // @include activeZone("slides") {
    //   &:after {
    //     opacity: 0;
    //   }
    // }
  }
  // #backgroundPlayBtn,#backgroundPlayBtnMobile {
  //   position: absolute;
  //   bottom: auto;
  //   top: 110px;
  //   width: 30px;
  //   height: 30px;
  // }
}
@media (max-width: $xxs) {
  #backgroundPlayBtn,
  #backgroundPlayBtnMobile {
    font-size: 1.125em;
  }
}
