@use "mixins" as *;
@use "variables" as *;

.membershipPage .errorPage {
  .errorWrap {
    padding: 30px 20px;
    border-top: 5px solid $primary;
    box-shadow: 0 2px 4px $lightGrey;
  }

  .icon {
    padding: 0 15px 15px 15px;
    font-size: 5em;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .rte {
    text-align: center;
    padding: 10px;
    width: 100%;
    h3.heading {
      color: $primary;
      &:before {
        display: none;
      }
    }
  }
}

@media (max-width: $sm) {
  .membershipPage .errorPage {
    .icon {
      font-size: 6em;
    }
  }
}

@media (max-width: $xs) {
  .membershipPage .errorPage {
    .errorWrap {
      flex-direction: column;
    }

    .icon {
      font-size: 5em;
      border-left: 0;
      padding-bottom: 15px;
      border-bottom: 2px solid rgb(167, 196, 221);
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }

    .rte {
      width: 100%;
      padding: 15px 0;
    }
  }
}
