@use "sass:meta";
@use "sass:math";

/*---TEXT AND FONT---*/

/// Create a text-shadow with clearer properties.
/// @param {*} $x [0px]
/// @param {*} $y [1px]
/// @param {*} $blur [0px]
/// @param {*} $color [rgba(0, 0, 0, 0.75)]
@mixin textShadow($x: 0px, $y: 1px, $blur: 0px, $color: rgba(0, 0, 0, 0.75)) {
  text-shadow: $x $y $blur $color;
}

/// Set icon font and normalize all properties.
/// @param {*} $font [$iconFont]
@mixin iconFont($font: $iconFont) {
  font-family: $font !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
}

/*---BACKGROUND---*/

/// Sets background to cover with 50% 50% position.
@mixin backgroundCover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/// Sets height and width to 100%.
@mixin fillSpace {
  height: 100%;
  width: 100%;
}

/// Create a box shadow with clearer properties.
/// @param {*} $x [0px]
/// @param {*} $y [0px]
/// @param {*} $blur [2px]
/// @param {*} $spread [2px]
/// @param {*} $color [rgba(0, 0, 0, 0.2)]
@mixin boxShadow($x: 0px, $y: 0px, $blur: 2px, $spread: 2px, $color: rgba(0, 0, 0, 0.2)) {
  box-shadow: $x $y $blur $spread $color;
}

/// Create a box shadow with clearer properties.
/// @param {*} $x [0px]
/// @param {*} $y [1px]
/// @param {*} $blur [3px]
/// @param {*} $spread [0px]
/// @param {*} $color [rgba(0, 0, 0, 0.2)]
@mixin shadow-inset($x: 0px, $y: 1px, $blur: 3px, $spread: 0px, $color: rgba(0, 0, 0, 0.2)) {
  box-shadow: inset $x $y $blur $spread $color;
}

/*---POSITIONING---*/

/// Sets element to position absolute to with top: 0 and left: 0;
@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
}

/// Sets element to position fixed to with top: 0 and left: 0;
@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
}

/// Resets position properties (top, bottom, left, right).
/// @param {*} $pos [relative] - Sets the position property.
@mixin resetPos($pos: relative) {
  position: $pos;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

/// Vertically centers an element using position absolute and transforms.
/// @param {number} $percent [50%] - Property top percentage.
/// @param {number} $trans [50%] - Property translateY percentage.
@mixin vertCenter($percent: 50%, $trans: 50%) {
  position: absolute;
  top: $percent;
  transform: translateY(-$trans);
  -webkit-transform: translateY(-$trans);
}

/// Vertically centers an element using position absolute and transforms.
/// @param {number} $percent [50%] - Property left percentage.
/// @param {number} $trans [50%] - Property translateX percentage.
@mixin horzCenter($percent: 50%, $trans: 50%) {
  position: absolute;
  left: $percent;
  transform: translateX(-$trans);
  -webkit-transform: translateX(-$trans);
}

@mixin vertHorzCenter {
  @include vertCenter;
  @include horzCenter;
  transform: translate(-50%, -50%);
}

/// Does what it says on the tin. Sets margin and padding to 0.
@mixin zeroMargPad {
  margin: 0;
  padding: 0;
}

/*---CONTENT---*/

/// Used inside ::before and ::after elements. Sets width and height to 100%.
@mixin pseudoContent {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
}

/// Creates an ::after element to prevent float overlap.
@mixin clearfix {
  &:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/*---LISTS---*/

/// Remove list styling, margin, and padding.
@mixin reformatList {
  list-style-type: none;
  @include zeroMargPad;

  li {
    @include zeroMargPad;
  }
}

/*---BORDER ARROWS---*/
/// Draw an arrow using css borders
/// @param {variable} $dir [top, right, bottom, left, topRight, bottomRight, topLeft, bottomLeft]
/// @param {color} $color [$light]
/// @param {number} $height [20px]
/// @param {number} $width [30px]
@mixin cssArrow($dir, $color: $primary, $height: 20px, $width: 20px) {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  @if $dir == top {
    border-width: 0 calc(#{$width} / 2) $height calc(#{$width} / 2);
    border-bottom-color: $color;
  } @else if $dir == right {
    border-width: calc(#{$height} / 2) 0 calc(#{$height} / 2) $width;
    border-left-color: $color;
  } @else if $dir == bottom {
    border-width: $height calc(#{$width} / 2) 0 calc(#{$width} / 2);
    border-top-color: $color;
  } @else if $dir == left {
    border-width: calc(#{$height} / 2) $width calc(#{$height} / 2) 0;
    border-right-color: $color;
  } @else if $dir == topRight {
    border-width: 0 $height $width 0;
    border-right-color: $color;
  } @else if $dir == bottomRight {
    border-width: 0 0 $height $width;
    border-bottom-color: $color;
  } @else if $dir == bottomLeft {
    border-width: $height 0 0 $width;
    border-left-color: $color;
  } @else if $dir == topLeft {
    border-width: $height $width 0 0;
    border-top-color: $color;
  } @else {
    @error "Unknown direction #{$dir}.";
  }
}

/// Draw a down arrow using css borders
/// @param {number} $size [8px]
/// @param {number} $sizeLR [$size]
/// @param {color} $color [$light]
/// @param {number} $top [auto]
/// @param {number} $left [auto]
@mixin rightArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $right: auto) {
  content: "";
  width: 0;
  height: 0;
  border-top: $sizeTB solid transparent;
  border-bottom: $sizeTB solid transparent;
  border-left: $size solid $color;
  border-right: 0;
  position: absolute;
  top: $top;
  right: $right;
}

/// Draw a down arrow using css borders
/// @param {number} $size [8px]
/// @param {number} $sizeLR [$size]
/// @param {color} $color [$light]
/// @param {number} $top [auto]
/// @param {number} $left [auto]
@mixin leftArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $left: auto) {
  content: "";
  width: 0;
  height: 0;
  border-top: $sizeTB solid transparent;
  border-bottom: $sizeTB solid transparent;
  border-left: 0;
  border-right: $size solid $color;
  position: absolute;
  top: $top;
  left: $left;
}

/// Draw a down arrow using css borders
/// @param {number} $size [8px]
/// @param {number} $sizeLR [$size]
/// @param {color} $color [$light]
/// @param {number} $top [auto]
/// @param {number} $left [auto]
@mixin topArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto) {
  content: "";
  width: 0;
  height: 0;
  border-left: $sizeLR solid transparent;
  border-right: $sizeLR solid transparent;
  border-bottom: $size solid $color;
  border-top: 0;
  position: absolute;
  top: $top;
  left: $left;
}

/// Draw a down arrow using css borders
/// @param {number} $size [8px]
/// @param {number} $sizeLR [$size]
/// @param {color} $color [$light]
/// @param {number} $top [auto]
/// @param {number} $left [auto]
@mixin bottomArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto) {
  content: "";
  width: 0;
  height: 0;
  border-left: $sizeLR solid transparent;
  border-right: $sizeLR solid transparent;
  border-top: $size solid $color;
  border-bottom: 0;
  position: absolute;
  top: $top;
  left: $left;
}

/// A kind of hacky mixin to force the browser to use a GPU to render the element.
@mixin useGPU {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}

/// Video background size.
/// @param {*} $bgImg - The background image. In the form of url(/image/my-image.jpg).
@mixin videoBG($bgImg) {
  width: 100%;
  @include absolute;
  overflow: hidden;

  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-image: $bgImg;
    @include backgroundCover;
  }
}

/**Forms**/

/// Input Placeholder styling
/// @param {color} $color [lighten($basic, 60%)] - The color of the placeholder text.
/// @param {*} $style [italic] - Font style.
/// @param {*} $font [$basicFont] - Font family.
/// @param {*} $size [1em] - Font size.
@mixin placeholder($color: lighten($basic, 70%), $style: italic, $font: $basicFont, $size: 1em) {
  &::-webkit-input-placeholder {
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &:-ms-input-placeholder {
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }
}

/// Breaks long words and urls
/// @author Karl Ross
@mixin wordBreak {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/// Hides an element but still allows it to be read by a screen reader.
/// @author Ben Richardson
@mixin screenReader() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Fixes legends on older browsers and smaller browsers.
@mixin legendFix {
  //This fixes for responsive
  display: table;
  /* Enable line-wrapping in IE8+ */
  white-space: normal;
  /* Enable line-wrapping in old versions of some other browsers */
  width: 100%;
}

/// Style scrollbars with all browser vendor properties.
/// @author Kourtney Strickland
/// @param {number} $width [5px] - The width of the scrollbar.
/// @param {color} $color [#444444] - The color of the scroll knob.
/// @param {color} $bgColor [#f5f5f5] - The background color of the scrollbar.
/// @param {boolean} $shadow [true] - Whether to draw a box shadow on the scrollbar.
@mixin scrollbar($width: 5px, $color: #444444, $bgColor: #f5f5f5, $shadow: true) {
  // Nonstandard webkit properties, works on Chrome, Modern Edge, and Safari
  &::-webkit-scrollbar-track {
    @if $shadow {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    background-color: $bgColor;
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    background-color: $bgColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }

  // The proper, standard way to style scrollbars. Supported in Firefox, Edge 121+, and Chrome 121+
  scrollbar-color: $color $bgColor;
  @if ($width < 15px) {
    scrollbar-width: thin;
  }
}

/// Shorthand for a flexbox container that justifes content at the start.
/// @author Kourtney Strickland
/// @param {string} $dir [row] - The flex-direction of the element.
/// @param {string} $justify [space-between] - The justify-content property of the element.
@mixin flexJustify($dir: row, $justify: space-between) {
  //alternate would be space-around
  display: flex;
  justify-content: $justify;
  align-items: center;
  flex-direction: $dir;
}

/// Shorthand for a flexbox container that justifes content in center.
/// @author Kourtney Strickland
/// @param {string} $dir [row] - The flex-direction of the element.
@mixin flexCenter($dir: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $dir;
}

/// Shorthand for a flexbox container that justifes content at the end.
/// @author Kourtney Strickland
/// @param {string} $dir [row] - The flex-direction of the element.
@mixin flexEnd($dir: row) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: $dir;
}

/// Shorthand for a flexbox container that justifes content at the start.
/// @author Kourtney Strickland
/// @param {string} $dir [row] - The flex-direction of the element.
@mixin flexStart($dir: row) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: $dir;
}

/// Creates a flexible horizontal layout of absolutely-positioned child elements of equal width.
/// Very useful for something like a main nav that transitions. Because everything is already absolute,
/// you can easily move sections out and in using the top/left properties.
/// @author Ben Richardson
/// @param {number} $max-num-of-children [7] - If the number of children exceeds this number, the layout will break.
/// @param {string} $orientation [vertical] - The direction of the flex container. Either "vertical" or "horizontal".
@mixin absoluteFlex($max-num-of-children: 7, $orientation: "vertical", $gutter: 0%) {
  @if type-of($max-num-of-children) !=number {
    @error "Input `$max-num-of-children` must be a number.";
  }

  @if $orientation != "horizontal" and $orientation != "vertical" {
    @error "Input `$max-num-of-children` must be set to either `horizontal` or `vertical`";
  }

  @while $max-num-of-children>1 {
    $size: math.div(100%, $max-num-of-children);
    $gutterSizeOffset: math.div($gutter, $max-num-of-children);

    // We can detect the number of items a parent has purely with CSS. Crazy, huh!
    > *:first-child:nth-last-child(#{$max-num-of-children}),
    > *:first-child:nth-last-child(#{$max-num-of-children}) ~ * {
      @if ($orientation== "horizontal") {
        width: calc(#{$size} - #{$gutter - $gutterSizeOffset});
      } @else if ($orientation== "vertical") {
        height: calc(#{$size} - #{$gutter - $gutterSizeOffset});
      }

      // Calculate and set child position.
      @for $i from 1 through $max-num-of-children {
        &:nth-child(#{$i}) {
          @if ($orientation== "horizontal") {
            left: calc(#{$size * $i - $size} + #{$gutter - $gutterSizeOffset * $i});
          } @else if ($orientation== "vertical") {
            top: $size * $i - $size;
          }
        }
      }
    }

    $max-num-of-children: $max-num-of-children - 1;
  }

  > * {
    position: absolute;
  }
}

/// Create a blur effect with fallbacks for unsupported browsers.
/// @author Ben Richardson
/// @param {color} $color - The background-color of the element.
/// @param {num} $opacity [85%] - The level of opacity desired.
@mixin frostedGlass($color: $light, $opacity: 85%) {
  background: rgba($color, $opacity);

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);

    @if ($opacity - 20% > 0) {
      background: rgba($color, $opacity - 20%);
    } @else {
      background: none;
    }
  }
}

/// Delivers bulletproof hover styling that prevents touch events from triggering
/// since 99% of the time that's not what you want. Includes fallbacks for older browsers.
/// We used to use any-pointer: fine but for some reason that was always returning true
/// on Android devices, even with no mouse attached. So it's back to regular old pointer: fine.
/// @author Ben Richardson
/// @content
@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  [data-browser="ie"] &:hover {
    @content;
  }

  .ie &:hover {
    @content;
  }
}

/// Delivers bulletproof focus-visible styling with fallbacks for older browsers. Requires
/// a JS polyfill (WICG focus-visible) for older browsers.
/// @author Ben Richardson
/// @content
/// @link https://github.com/WICG/focus-visible
@mixin focus() {
  &.focus-visible {
    @content;
  }

  &:focus-visible {
    @content;
  }
}

/// @mixin hover and @mixin focus styling in one mixin.
/// @author Ben Richardson
/// @content
@mixin hoverAndFocus() {
  @include hover {
    @content;
  }

  @include focus {
    @content;
  }
}

/// Accessible backgrounds! Ensure your elements always have proper contrast by setting both background-color and color properties.
/// @author Ben Richardson
/// @param {string} $bg - The background to use for the element.
/// @param {string} $textDark [dark] - Text color to use when element's background is above a certain threshold in lightness.
/// @param {string} $textLight [white] - Text color to use when element's background is below a certain threshold in lightness.
@mixin bgWithContrast($bg, $textDark: black, $textLight: white) {
  background-color: $bg;

  $ratioWithDark: wcag-contrast-ratio($bg, $textDark);
  $ratioWithLight: wcag-contrast-ratio($bg, $textLight);

  @if $ratioWithDark < 3 and $ratioWithLight < 3 {
    @error "The color #{$bg} does not meet WCAG AA minimum accessibility contrast ratio of 3:1. Please choose a different color combination.";
  } @else if $ratioWithDark < 4.5 and $ratioWithLight < 4.5 {
    @warn "The color #{$bg} does not meet WCAG AA minimum accessibility contrast ratio of 4.5:1. Please confirm that any text inside this element is greater than 18px.";
  }

  @if $ratioWithDark >= $ratioWithLight {
    color: $textDark;
  } @else {
    color: $textLight;
  }
}

/// Accessible text! Ensure your elements always have proper contrast by setting both color and background-color properties.
/// @author Ben Richardson
/// @param {string} $textColor - The text color to use for the element.
/// @param {string} $bgDark [dark] - Background color to use when element's text is above a certain threshold in lightness.
/// @param {string} $bgLight [white] - Background color to use when element's text is below a certain threshold in lightness.
@mixin colorWithContrast($textColor, $bgDark: black, $bgLight: white) {
  color: $textColor;

  $ratioWithDark: wcag-contrast-ratio($textColor, $bgDark);
  $ratioWithLight: wcag-contrast-ratio($textColor, $bgLight);

  @if $ratioWithDark < 3 and $ratioWithLight < 3 {
    @error "The color #{$textColor} does not meet WCAG AA minimum accessibility contrast ratio of 3:1. Please choose a different color combination.";
  } @else if $ratioWithDark < 4.5 and $ratioWithLight < 4.5 {
    @warn "The color #{$textColor} does not meet WCAG AA minimum accessibility contrast ratio of 4.5:1. Please confirm that any text inside this element is greater than 18px.";
  }

  @if $ratioWithDark >= $ratioWithLight {
    background-color: $bgDark;
  } @else {
    background-color: $bgLight;
  }
}

/// Returns a contrasting color value to the given color.
/// @author Ben Richardson
/// @param {string} $color - The color to contrast again.
/// @param {string} $dark [dark] - Background color to use when $color is above a certain threshold in lightness.
/// @param {string} $light [white] - Background color to use when $color is below a certain threshold in lightness.
/// @return {string} - Light or dark depending on the color given.
@function contrast($color, $dark: black, $light: white) {
  $ratioWithDark: wcag-contrast-ratio($color, $dark);
  $ratioWithLight: wcag-contrast-ratio($color, $light);

  @if $ratioWithDark < 3 and $ratioWithLight < 3 {
    @error "The color #{$color} does not meet WCAG AA minimum accessibility contrast ratio of 3:1. Please choose a different color combination.";
  } @else if $ratioWithDark < 4.5 and $ratioWithLight < 4.5 {
    @warn "The color #{$color} does not meet WCAG AA minimum accessibility contrast ratio of 4.5:1. Please confirm that any text inside this element is greater than 18px.";
  }

  @if $ratioWithDark >= $ratioWithLight {
    @return $dark;
  } @else {
    @return $light;
  }
}

/// Sets background-position css based on [data-bg-pos="x y"].
/// @author Ben Richardson
@mixin bgPos() {
  &[data-bg-pos="top left"] {
    background-position: 0% 0%;
  }
  &[data-bg-pos="top middle"] {
    background-position: 50% 0;
  }
  &[data-bg-pos="top right"] {
    background-position: 100% 0%;
  }
  &[data-bg-pos="center left"] {
    background-position: 0% 50%;
  }
  &[data-bg-pos="center"] {
    background-position: 50% 50%;
  }
  &[data-bg-pos="center right"] {
    background-position: 100% 50%;
  }
  &[data-bg-pos="bottom left"] {
    background-position: 0% 100%;
  }
  &[data-bg-pos="bottom middle"] {
    background-position: 50% 100%;
  }
  &[data-bg-pos="bottom right"] {
    background-position: 100% 100%;
  }
}

/// Triggers styling based on whether a given dialog is active or not. Can be used nested or standalone.
/// @author Ben Richardson
/// @param {string} $id - The id of a dialog.
/// @content - optional
@mixin activeDialog($id) {
  @if & {
    [data-active-dialog="#{$id}"] & {
      @content;
    }
  } @else {
    [data-active-dialog="#{$id}"] {
      @content;
    }
  }
}

/// Triggers styling based on whether a given zone is active or not. Can be used nested or standalone.
/// @author Ben Richardson
/// @param {string} $id - The id of a zone.
/// @content - optional
@mixin activeZone($id) {
  @if & {
    [data-active-zone="#{$id}"] & {
      @content;
    }
  } @else {
    [data-active-zone="#{$id}"] {
      @content;
    }
  }
}

/// Returns aspect-ratio with support for older browsers using padding-bottom percentage trick.
/// @author Ben Richardson
/// @param {num} $h - The horizontal aspect ratio of the element.
/// @param {num} $v - The vertical aspect ratio of the element.
@mixin aspectRatio($h, $v) {
  @supports (aspect-ratio: 1 / 1) {
    aspect-ratio: $h #{"/"} $v;
  }
  @supports not (aspect-ratio: 1 / 1) {
    &:after {
      content: "";
      display: block;
      padding-bottom: math.div($v, $h) * 100%;
    }
  }
}

/// Triggers styling based on whether any discovery tool dialogs are active. Can be used nested or standalone.
/// @author Ben Richardson
/// @content - optional
@mixin discoveryToolsActive() {
  @if & {
    [data-active-dialog="checklistAndAnnouncements"] &,
    [data-active-dialog="faq"] &,
    [data-active-dialog="glossary"] &,
    [data-active-dialog="achievements"] &,
    [data-active-dialog="resources"] &,
    [data-active-dialog="faqConfirmQuestionMessage"] &,
    [data-active-dialog="faqQuestionSubmittedMessage"] & {
      @content;
    }
  } @else {
    [data-active-dialog="checklistAndAnnouncements"],
    [data-active-dialog="faq"],
    [data-active-dialog="glossary"],
    [data-active-dialog="achievements"],
    [data-active-dialog="resources"],
    [data-active-dialog="faqConfirmQuestionMessage"],
    [data-active-dialog="faqQuestionSubmittedMessage"] {
      @content;
    }
  }
}

@function wcag-contrast-ratio($color1, $color2) {
  $l1: wcag-luminance($color1) + 0.05;
  $l2: wcag-luminance($color2) + 0.05;
  $ratio: math.div($l1, $l2);

  @if $l2 > $l1 {
    $ratio: math.div(1, $ratio);
  }

  @return $ratio;
}

/// Returns the luminance of `$color` as a float (between 0 and 1)
/// 1 is pure white, 0 is pure black
/// @param {Color} $color - Color
/// @return {Number}
/// @link http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef Reference
@function wcag-luminance($color) {
  $colors: (
    "red": red($color),
    "green": green($color),
    "blue": blue($color)
  );

  @each $name, $value in $colors {
    $adjusted: 0;
    $value: math.div($value, 255);

    @if $value < 0.03928 {
      $value: math.div($value, 12.92);
    } @else {
      $value: math.div(($value + 0.055), 1.055);
      $value: math.pow($value, 2.4);
    }

    $colors: map-merge(
      $colors,
      (
        $name: $value
      )
    );
  }

  @return (map-get($colors, "red") * 0.2126) + (map-get($colors, "green") * 0.7152) + (map-get($colors, "blue") * 0.0722);
}

/** DEFAULT OVERRIDES **/

/// Remove all default styles from buttons
/// @author Kourtney Strickland
@mixin resetButton() {
  background: transparent;
  border: 0px;
  box-shadow: none;
  outline: 0px;
  display: block;
  text-align: unset;
  align-items: unset;
  padding: 0px;
  cursor: pointer;
  color: unset;
}

/// Apply styling to a parent based on number of children.
/// @author Ben Richardson
/// @param {number} $num - The number of children to check.
/// @param {boolean} $orMore [false] - Applies if element has above the given number of children.
/// @param {boolean} $orLess [false] - Applies if element has below the given number of children.
/// @link https://css-tip.com/number-elements-has-selector/
@mixin hasNumberOfChildren($num, $orMore: false, $orLess: false) {
  @if ($orMore == true and $orLess == true) {
    @error "Above and below cannot both be true.";
  }
  @if ($num == 0) {
    &:not(:has(*)) {
      @content;
    }
  } @else {
    @if ($orMore == true) {
      &:has(> :nth-child(#{$num})) {
        @content;
      }
    } @else if ($orLess == true) {
      &:has(> :last-child:nth-child(-n + #{$num})) {
        @content;
      }
    }
    &:has(> :last-child:nth-child(#{$num})) {
      @content;
    }
  }
}
